import { createContext, useState } from 'react'
import { ServiceShow, ServiceContextProviderProps, ServiceContextType } from '../../services.types'

export const ServiceContext = createContext<ServiceContextType>({
  service: null,
})

export const ServiceContextProvider = ({ children }: ServiceContextProviderProps) => {
  const [service, setService] = useState<ServiceShow | null>(null)

  return (
    <ServiceContext.Provider value={{ service, setService }}>{children}</ServiceContext.Provider>
  )
}
