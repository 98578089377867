export enum ActionEnum {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  AuthIsReady = 'AUTH_IS_READY',
}

export interface AuthContextProviderProps {
  children: React.ReactNode
}

export interface ActionType {
  type: ActionEnum
  payload: string | null
}

export interface AuthState {
  token: string | null
}

export interface DispatchFunctionType {
  dispatch?: React.Dispatch<ActionType>
}

export type AuthContextType = AuthState & DispatchFunctionType
