import { createContext, useState } from 'react'
import {
  RequirementContextProviderProps,
  RequirementPropsTableType,
  RequirementPropsTableContextType,
} from '../../requirements.types'

export const TablePropsContext = createContext<RequirementPropsTableContextType>({
  requirementPropsTable: null,
})

export const TablePropsContextProvider = ({ children }: RequirementContextProviderProps) => {
  const [requirementPropsTable, setRequirementPropsTable] = useState<RequirementPropsTableType | null>(
    null
  )

  return (
    <TablePropsContext.Provider value={{ requirementPropsTable, setRequirementPropsTable }}>
      {children}
    </TablePropsContext.Provider>
  )
}
