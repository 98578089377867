import moment from 'moment'
import { Entry } from '../../../activity.types'

export const RequirementsItemsAccepted = ({
  entry,
  canHaveTimeline,
}: {
  entry: Entry
  canHaveTimeline: boolean
}) => {
  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-success">
        <i className="fs-3 bi text-white activity__icon bi-check"></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <p className="activity__text">
            <span className="me-2">
              <span className="activity__span fw-bold">{entry.user.name}</span>
              ha aceptado el item del requerimiento
            </span>
            <span className="activity__date text-secondary fs-7 d-inline-block">
              {moment(entry.created_at).format('DD MMM h:mm A')}
            </span>
          </p>
        </div>
        <div className="activity__body me-3 pb-2 border-bottom border-1"></div>
      </div>
    </div>
  )
}
