import { createContext, useState } from 'react'
import { Partner, PartnerContextProviderProps, PartnerContextType } from '../../marketplace.types'

export const PartnerContext = createContext<PartnerContextType>({
  partner: null,
  setPartner: () => {}
})

export const PartnerContextProvider = ({ children }: PartnerContextProviderProps) => {
  const [partner, setPartner] = useState<Partner | null>(null)

  return <PartnerContext.Provider value={{ partner, setPartner }}>{children}</PartnerContext.Provider>
}
