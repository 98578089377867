import { useState } from "react"
import { axiosPrivate } from "../../../setup/axios"

import { useAuthContext } from "../../../setup/hooks/useAuthContext"
import { ActionEnum } from "../../../setup/auth.types"

export const useAccessToken = (): {
  loading: boolean
  access: () => Promise<void>
} => {
  const [loading, setLoading] = useState<boolean>(true)
  const { dispatch } = useAuthContext()

  const access = async (): Promise<void> => {
    if (localStorage.getItem("token")) {
      axiosPrivate.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`
      //Check if token is valid or show login

      if (dispatch != null) {
        dispatch({
          type: ActionEnum.AuthIsReady,
          payload: localStorage.getItem("token"),
        })
      }

      setLoading(false)
      return
    } else {
      setLoading(false)
    }
  }

  return { loading, access }
}
