import React from 'react'
import { useBreadcrumbContext } from './hooks/useBreadcrumbContext'
import { NavigationLinks } from './NavigationLinks'

export const Breadcrumb = (): React.ReactElement => {
  const { breadcrumb } = useBreadcrumbContext()

  return (
    <>
      <h1 className="text-dark fw-bold my-1 fs-2 lh-1">{breadcrumb?.breadcrumbTitle || breadcrumb?.title}</h1>
      <NavigationLinks links={breadcrumb?.links} />
    </>
  )
}
