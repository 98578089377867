import { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ApplicationLayout from '../../layouts/ApplicationLayout'
import ServicesPage from '../../pages/Private/ServicesPage'
import { AccountContextProvider } from '../contexts/AccountContext'
import { BreadcrumbContextProvider } from '../../components/breadcrumb/contexts/BreadcrumbContext'
import { Page404 } from '../../pages/Page404'
import SuspensedView from '../../components/SuspensedView'

const LazyProfilePage = lazy(async () => import('../../pages/Private/ProfilePage'))
const LazyAdminPage = lazy(async () => import('../../pages/Private/AdminPage'))

export const PrivateRoutes = (): React.ReactElement => {
  return (
    <AccountContextProvider>
      <BreadcrumbContextProvider>
        <Routes>
          <Route element={<ApplicationLayout />}>
            {/* Redirect to services after success login/registartion */}
            <Route path="auth/*" element={<Navigate to="/services" />} />
            {/* Pages */}
            <Route
              path="admin/*"
              element={
                <SuspensedView>
                  <LazyAdminPage />
                </SuspensedView>
              }
            />
            <Route
              path="profile/*"
              element={
                <SuspensedView>
                  <LazyProfilePage />
                </SuspensedView>
              }
            />
            <Route path="services/*" element={<ServicesPage />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BreadcrumbContextProvider>
    </AccountContextProvider>
  )
}
