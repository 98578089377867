import { useMemo } from 'react'

type AttachmentPreviewProps = {
  extension: string
  version: string
}

export const AttachmentPreview = ({ extension, version }: AttachmentPreviewProps) => {
  const workExtension = useMemo(() => {
    return extension.split('.').slice(-1)[0].toLowerCase()
  }, [extension])

  if (['png', 'jpeg', 'gif', 'jpg'].includes(workExtension)) {
    return <img src={version} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
  }

  if (workExtension.includes('pdf')) {
    return <i className="bi bi-file-earmark-pdf-fill text-danger activity__attachmentIcon mb-4"></i>
  }

  if (workExtension.includes('xlsx')) {
    return <i className="bi bi-file-earmark-excel-fill activity__attachmentIcon mb-4"></i>
  }

  if (workExtension.includes('docx')) {
    return <i className="bi bi-file-earmark-word-fill activity__attachmentIcon mb-4"></i>
  }

  return <i className="bi bi-file-earmark-fill activity__attachmentIcon mb-4"></i>
}
