import { useContext } from 'react'
import { BreadcrumbContext } from '../contexts/BreadcrumbContext'
import { BreadcrumbContextType } from '../breadcrumb.types'

export const useBreadcrumbContext = (): BreadcrumbContextType => {
  const context = useContext(BreadcrumbContext)

  if (!context) {
    throw Error(
      'useBreadcrumbContext must be inside an BreadcrumbContextProvider'
    )
  }

  return context
}
