import { useContext } from 'react'
import { AccountContext } from '../contexts/AccountContext'
import { AccountContextType } from '../account.types'

export const useAccountContext = (): AccountContextType => {
  const context = useContext(AccountContext)

  if (!context) {
    throw Error('useAccountContext must be inside an AccountContextProvider')
  }

  return context
}
