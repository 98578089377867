import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ForgotPassword } from '../features/authentication/ForgotPassword'
import { ResetPassword } from '../features/authentication/ResetPassword'
import { AcceptInvitation } from '../features/authentication/AcceptInvitation'
import { Login } from '../features/authentication/Login'
import AuthLayout from '../layouts/AuthLayout'

export const AuthPage = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="new_password" element={<ResetPassword />} />
        <Route path="invitations" element={<AcceptInvitation />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}
