export const EmptyRowsRenderer = ({ message }: { message: string }) => {
  return (
    <div className="my-2 text-muted fs-5" style={{ textAlign: 'center', gridColumn: '1/-1' }}>
      {message}
    </div>
  )
}

export const InputStopPropagation = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation()
  }
}

export const SelectStopPropagation = (event: React.KeyboardEvent<HTMLSelectElement>) => {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation()
  }
}

export const initialFilters = {
  dispatch_guide: '',
  supplier_dispatch_guide: '',
  order: '',
  supplier: '',
  warehouse: '',
  location: '',
  description: '',
  enabled: true,
  page: 1,
  cost_center: ''
}
