import { roleLabel } from '../locale'

type RibbonBadgeProps = {
  role: string
}

export const RibbonBadge = ({ role }: RibbonBadgeProps) => {
  switch (role) {
    case 'admin':
      return <div className="badge bg-light-primary text-uppercase fs-8">{roleLabel(role)}</div>
    case 'responsible':
      return <div className="badge bg-light-primary text-uppercase fs-8">{roleLabel(role)}</div>
    case 'participant':
      return (
        <div className="badge bg-secondary text-muted text-uppercase fs-8">{roleLabel(role)}</div>
      )
    case 'notification_receiver':
      return (
        <div className="badge bg-secondary text-muted text-uppercase fs-8">{roleLabel(role)}</div>
      )
    default:
      return <span>{role}</span>
  }
}
