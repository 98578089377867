import { useContext } from 'react'
import { LastReservationContext } from '../contexts/LastReservationContext'
import { LastReservationContextType } from '../../reservations.types'

export const useLastReservationContext = (): LastReservationContextType => {
  const context = useContext(LastReservationContext)

  if (!context) {
    throw Error('useLastReservationContext must be inside an useLastReservationContextProvider')
  }

  return context
}
