import NoData from '../../../../assets/images/undraw/no-data.svg'
import { FileActivity } from '../../activity.types'
import { AttachmentItem } from './AttachmentItem'

type AttachmentManagementProps = {
  attachments: FileActivity[]
  title?: string
}
export const AttachmentManagement = ({ attachments, title }: AttachmentManagementProps) => {
  if (attachments.length === 0) return <img alt="404" src={NoData} className="h-100px" />
  return (
    <div className="activity__attachementContainer d-flex flex-column">
      <span className="fw-bold text-secondary my-2">{title || 'Archivos'}</span>
      <div className="d-flex flex-wrap">
        {attachments.map((attachments: FileActivity, index: number) => (
          <AttachmentItem key={index} attachment={attachments} />
        ))}
      </div>
    </div>
  )
}
