import { createContext, useState } from "react"
import { DispatchGuide, DispatchGuideContextProviderProps, DispatchGuideContextType } from "../../dispatch_guides.types"

export const DispatchGuideContext = createContext<DispatchGuideContextType>({
  dispatchGuide: null,
  setDispatchGuide: () => {},
  isMovingDispatchGuide: false,
  setIsMovingDispatchGuide: () => {},
})

export const DispatchGuideContextProvider = ({ children }: DispatchGuideContextProviderProps) => {
  const [dispatchGuide, setDispatchGuide] = useState<DispatchGuide | null>(null)
  const [isMovingDispatchGuide, setIsMovingDispatchGuide] = useState<boolean>(false)

  return (
    <DispatchGuideContext.Provider
      value={{ dispatchGuide, setDispatchGuide, isMovingDispatchGuide, setIsMovingDispatchGuide }}
    >
      {children}
    </DispatchGuideContext.Provider>
  )
}
