import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useInvitation } from '../../../../features/invitations/hooks/useInvitation'
import { InvitationFormAsync } from '../../../../features/invitations/InvitationFormAsync'
import { NewUserType } from '../../../services.types'
import { useDeleleteServiceUser, useListUsersByService } from '../../hooks/useService'
import { useServiceContext } from '../../hooks/useServiceContext'
import { ServiceTeamParticipants } from './ServiceTeamParticipants'

type ServiceTeamModalProps = {
  resourceId: string | undefined
  resourceType: string
  canRegisterNewInvitation: boolean | undefined
  title: string
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  invitationTitle: string
}
export const ServiceTeamModal = ({
  resourceId,
  resourceType,
  canRegisterNewInvitation,
  title,
  setIsOpen,
  invitationTitle
}: ServiceTeamModalProps) => {
  const [addNewParticipant, setAddNewParticipant] = useState<boolean>(false)

  const { service } = useServiceContext()
  const { data: users, isPending: isPendingUsers } = useListUsersByService(resourceId)
  const { mutate: sendInvitation, isPending: sendingInvitation } = useInvitation({
    resourceType,
    resourceId
  })
  const { mutate: deleteServiceUser, isPending: deletingUser } = useDeleleteServiceUser({
    resourceType,
    resourceId
  })

  const onDeleteServiceUser = (userId: number) => {
    if (deletingUser) return
    deleteServiceUser(
      { resourceType, resourceId, userId },
      {
        onSuccess: () => {
          toast.success('Usuario eliminado')
        },
        onError: () => {
          toast.error('Error al eliminar usuario')
        }
      }
    )
  }

  const onSubmit: SubmitHandler<NewUserType> = (invitation: NewUserType) => {
    if (sendingInvitation) {
      return
    }

    sendInvitation(
      { resourceType, resourceId, invitation },
      {
        onSuccess: () => {
          toast.success('Invitación enviada')
          setAddNewParticipant(false)
        },
        onError: () => {
          toast.error('Datos invalidos')
        }
      }
    )
  }
  return (
    <Modal show={true} onHide={() => setIsOpen(false)} size={addNewParticipant ? undefined : 'lg'}>
      <Modal.Header
        className={
          'd-flex align-items-center justify-content-between ' + (addNewParticipant ? 'pb-0' : 'border-bottom')
        }
      >
        <div className="fs-2 fw-bold d-flex align-items-center">
          {addNewParticipant ? (
            <i
              className="bi bi-arrow-left fs-1 text-primary cursor-pointer me-4"
              onClick={() => setAddNewParticipant(false)}
            ></i>
          ) : (
            <div>{title}</div>
          )}
        </div>
        <i className="bi bi-x-lg fs-1 cursor-pointer " onClick={() => setIsOpen(false)} />
      </Modal.Header>
      <Modal.Body className="pt-0 d-flex flex-column justify-content-center align-items-center">
        {!addNewParticipant ? (
          <ServiceTeamParticipants
            users={users}
            isPending={isPendingUsers}
            canRegisterNewInvitation={canRegisterNewInvitation}
            handleClick={() => setAddNewParticipant(true)}
            onDelete={onDeleteServiceUser}
          />
        ) : (
          <InvitationFormAsync
            resourceId={String(resourceId)}
            resourceType={resourceType}
            allowedRoles={service?.allowed_roles}
            onSubmit={onSubmit}
            isPending={sendingInvitation}
            title={invitationTitle}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
