import { useState } from 'react'
import { ServiceMenu } from './ServiceMenu'

export const ServiceMobileMenu = () => {
  const [sectionOpen, setSectionOpen] = useState<boolean>(false)

  return (
    <div className="portal__menu mb-4">
      <div
        className={
          'position-absolute btn btn-secondary btm-sm p-5 w-35px h-35px d-flex justify-content-center align-items-center rounded cursor-pointer portal__menu-options' +
          (sectionOpen ? ' active' : '')
        }
        onClick={() => setSectionOpen(!sectionOpen)}
      >
        <i className="bi bi-info-square fs-3 portal__icon-menu"></i>
      </div>
      {sectionOpen && (
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-body p-0">
              <section className="mx-2 d-flex ">
                <ServiceMenu />
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
