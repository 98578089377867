import { Link } from 'react-router-dom'
import Logo from '../../../assets/images/dashboard_logo_white.svg'
import StagingLogo from '../../../assets/images/staging_logo.svg'
import AsideMenu from './AsideMenu'
import { useMenuContext } from '../../../components/menu/useMenuContext'

export const AsideDefault = (): React.ReactElement => {
  const { visible, setVisible } = useMenuContext()
  const logo = import.meta.env.VITE_REACT_APP_STAGING ? StagingLogo : Logo

  return (
    <>
      <div
        className={
          visible
            ? 'aside aside-fixed pb-5 pt-5 pt-lg-0 drawer drawer-start drawer-on'
            : 'aside aside-fixed pb-5 pt-5 pt-lg-0 '
        }
      >
        <div className="aside-logo py-8">
          <Link to="/services" className="d-flex align-items-center">
            <img src={logo} alt="logo" className="h-60px logo" />
          </Link>
        </div>

        <div className="aside-menu flex-column-fluid">
          <AsideMenu />
        </div>
      </div>

      {visible && (
        <div
          style={{ zIndex: 109 }}
          role="button"
          tabIndex={0}
          className="drawer-overlay"
          onClick={() => setVisible(false)}
        ></div>
      )}
    </>
  )
}
