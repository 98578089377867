import React, { useRef } from 'react'
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions'
import { useServiceContext } from '../../../../Services/ServicesShow/hooks/useServiceContext'
import { useListUsersByService } from '../../../../Services/ServicesShow/hooks/useService'
import UserAvatar from '../../../users/UserAvatar'

type EntryInputProps = {
  comment: string
  setComment: React.Dispatch<React.SetStateAction<string>>
  numLetters: number
  setNumLetters: React.Dispatch<React.SetStateAction<number>>
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const maxLength = 1500

// Definimos un tipo personalizado que extiende SuggestionDataItem
interface CustomSuggestionDataItem extends SuggestionDataItem {
  tag?: {
    name: string
    identifier?: string
  }
  avatar?: {
    versions?: {
      default?: string
      square?: string
    }
  }
  email?: string
  role?: string
  [key: string]: any // Permite propiedades adicionales si es necesario
}

export const EntryInput = ({
  comment,
  setComment,
  numLetters,
  setNumLetters,
  handleFileChange,
  placeholder
}: EntryInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { service } = useServiceContext()
  const { data: users, isPending: isPendingServiceUsers } = useListUsersByService(service?.id)

  // Mantenemos la estructura original de mentionUsers
  const mentionUsers: CustomSuggestionDataItem[] =
    users?.map((user: any) => ({
      ...user,
      display: user.name
    })) || []

  const handleChange = (_event: any, newValue: string) => {
    setNumLetters(newValue.length)
    setComment(newValue)
  }

  const defaultPlaceholder = 'Si es necesario, por favor deja una observación o comentario para este recurso.'

  if (isPendingServiceUsers) return null

  return (
    <div className="activity__inputContainer">
      <div style={{ height: '120px' }}>
        <MentionsInput
          value={comment}
          onChange={handleChange}
          className="activity__textArea"
          placeholder={placeholder || defaultPlaceholder}
          maxLength={maxLength}
          allowSuggestionsAboveCursor={true}
        >
          <Mention
            trigger="@"
            data={mentionUsers}
            appendSpaceOnAdd
            displayTransform={(_id: any, display: any) => `@${display}`}
            renderSuggestion={(suggestion: CustomSuggestionDataItem, _search, highlightedDisplay, _index) => (
              <div key={suggestion.id} className="d-flex align-items-center px-5 my-1">
                <UserAvatar user={suggestion} size={'36'} round={true} />
                <div className="d-flex flex-column ms-2">
                  <div className="">{highlightedDisplay}</div>
                  {suggestion.tag?.name && <div className="text-success">{suggestion.tag.name}</div>}
                </div>
              </div>
            )}
          />
        </MentionsInput>
      </div>
      <div className="activity__toolkitTextarea mt-2 d-flex justify-content-between align-items-center">
        <span className="text-muted fw-semi fs-7 activity__countLetter">
          {numLetters}/{maxLength}
        </span>
        <div>
          <i
            className="bi bi-paperclip fs-6 activity__attachmentIcon cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          ></i>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
        </div>
      </div>
    </div>
  )
}
