import { Modal } from 'react-bootstrap'
import './modals.css'
import { useEffect } from 'react'

type ConfirmationModalProps = {
  isOpen: boolean
  title: string
  message: string
  action: string
  theme: string
  onConfirm: () => void
  onDismiss: () => void
}

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  action,
  theme,
  onConfirm,
  onDismiss,
}: ConfirmationModalProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen) {
        event.stopPropagation()
        event.preventDefault()
        if (event.key === 'Enter') {
          onConfirm()
        } else if (event.key === 'Escape') {
          onDismiss()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])
  return (
    <Modal
      backdropClassName="modals-modal-backdrop"
      className={'modals-modal-top'}
      show={isOpen}
      onHide={onDismiss}
      animation
    >
      <Modal.Header closeButton className="border-bottom">
        <Modal.Title>
          <i className={`bi bi-exclamation-triangle-fill text-${theme} fs-3 me-2`}></i> {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fs-5 mb-0">{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary btn-sm" onClick={onDismiss}>
          Cancelar
        </button>
        <button className={`btn btn-light-${theme}`} onClick={onConfirm}>
          {action}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
