import { createContext, useEffect, useState } from 'react'

import { BreadcrumbContextProviderProps, BreadcrumbContextType, BreadcrumbType } from '../breadcrumb.types'
import defaultImage from '../../../assets/images/marketplace/default-image.webp'

export const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumb: { title: '', links: [], description: '', image: '', showMina365OnTitle: false, breadcrumbTitle: '' },
  setBreadcrumb: () => {}
})

export const BreadcrumbContextProvider = ({ children }: BreadcrumbContextProviderProps): React.ReactElement => {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbType | null>({
    title: '',
    links: [],
    description: '',
    image: '',
    breadcrumbTitle: '',
    showMina365OnTitle: false
  })

  useEffect(() => {
    document.title = breadcrumb?.showMina365OnTitle ? `${breadcrumb?.title} | Mina365` : `${breadcrumb?.title || ''}`

    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute('content', breadcrumb?.description || '')
    } else {
      const meta = document.createElement('meta')
      meta.name = 'description'
      meta.content = breadcrumb?.description || ''
      document.head.appendChild(meta)
    }

    const canonicalLink = document.querySelector('link[rel="canonical"]')
    if (canonicalLink) {
      canonicalLink.setAttribute('href', window.location.href)
    } else {
      const link = document.createElement('link')
      link.rel = 'canonical'
      link.href = window.location.href
      document.head.appendChild(link)
    }

    const updateMetaTag = (property: string, content: string) => {
      let tag = document.querySelector(`meta[property="${property}"]`)
      if (tag) {
        tag.setAttribute('content', content)
      } else {
        tag = document.createElement('meta')
        tag.setAttribute('property', property)
        tag.setAttribute('content', content)
        document.head.appendChild(tag)
      }
    }

    updateMetaTag('og:title', breadcrumb?.title || '')
    updateMetaTag('og:description', breadcrumb?.description || '')
    updateMetaTag('og:image', breadcrumb?.image || defaultImage) // Assuming you add 'image' field in breadcrumb
    updateMetaTag('og:url', window.location.href)

    updateMetaTag('twitter:card', breadcrumb?.image ? 'summary_large_image' : 'summary')
    updateMetaTag('twitter:title', breadcrumb?.title || '')
    updateMetaTag('twitter:description', breadcrumb?.description || '')
    updateMetaTag('twitter:image', breadcrumb?.image || defaultImage)
  }, [breadcrumb?.title, breadcrumb?.description, breadcrumb?.image, breadcrumb?.showMina365OnTitle])

  return <BreadcrumbContext.Provider value={{ breadcrumb, setBreadcrumb }}>{children}</BreadcrumbContext.Provider>
}
