import { Route, Routes } from 'react-router-dom'
import ServicesIndex from '../../Services/pages/ServicesIndex'
import ServicesShow from '../../Services/pages/ServicesShow'

const ServicesPage = () => {
  return (
    <Routes>
      <Route index element={<ServicesIndex />} />
      <Route path=":id/*" element={<ServicesShow />} />
    </Routes>
  )
}

export default ServicesPage
