import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'
import { CreateUsersProps, GetUsersProps, User } from '../invitation.types'

const sendInvitation = ({ resourceType, resourceId, invitation }: CreateUsersProps) => {
  return axiosPrivate.post(`/${resourceType}/${resourceId}/invitations`, {
    invitation,
  })
}

export const useInvitation = ({ resourceType, resourceId }: GetUsersProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: sendInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', resourceType, resourceId]
      })
    },
  })
}

const getUsers = ({ resourceType, resourceId }: GetUsersProps): Promise<User[]> => {
  return axiosPrivate.get(`/${resourceType}/${resourceId}/users`).then((res) => {
    return res.data.users
  })
}

export const useGetUsers = ({ resourceType, resourceId }: GetUsersProps) => {
  return useQuery({
    queryKey: ['users', resourceType, resourceId],
    queryFn: () => getUsers({ resourceType, resourceId })
  })
}

const deleteUser = ({
  resourceType,
  resourceId,
  userId,
}: {
  resourceType: string
  resourceId: number
  userId: number
}) => {
  return axiosPrivate.delete(`/${resourceType}/${resourceId}/invitations/${userId}`)
}

export const useDeleteUser = ({ resourceType, resourceId }: GetUsersProps) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', resourceType, resourceId]
      })
    },
  })
}
