import Avatar from 'react-avatar'
import { versionImage } from '../../helpers/imageHelper'

interface UserAvatarProps {
  user: any
  size: string
  round: string | boolean
}

const UserAvatar = ({ user, size, round }: UserAvatarProps) => {
  return user?.avatar?.versions?.default ? (
    <Avatar
      src={versionImage(user?.avatar.versions, user?.avatar.processed, 'square')}
      name={user.name}
      size={size}
      round={round}
    />
  ) : (
    <Avatar name={user?.name} size={size} round={round} />
  )
}

export default UserAvatar
