import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'
import { NewUserType } from '../../services.types'

interface CreateInvitationServiceProps {
  serviceId: string | undefined
  invitation: NewUserType | null
}

type updateProfileProps = {
  serviceId: string | undefined
  profile: { tag_identifier: string }
}

const getListUsersByService = (serviceId: string | undefined) => {
  return axiosPrivate.get(`/services/${serviceId}/users`).then((response) => response.data.users)
}

export const useListUsersByService = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['users', 'services', serviceId],
    queryFn: async () => await getListUsersByService(serviceId)
  })
}

const deleteServiceUser = ({
  resourceType,
  resourceId,
  userId
}: {
  resourceType: string
  resourceId: string | undefined
  userId: number
}) => {
  return axiosPrivate.delete(`/${resourceType}/${resourceId}/invitations/${userId}`)
}

export const useDeleleteServiceUser = ({
  resourceType,
  resourceId
}: {
  resourceType: string
  resourceId: string | undefined
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteServiceUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', resourceType, resourceId]
      })
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'columns-users'
        }
      })
    }
  })
}

const getListServices = () => {
  return axiosPrivate.get('/services').then((response) => response.data.services)
}

export const useListServices = () => {
  return useQuery({
    queryKey: ['services-index'],
    queryFn: getListServices
  })
}

const sendInvitationService = ({ serviceId, invitation }: CreateInvitationServiceProps) => {
  return axiosPrivate.post(`/services/${serviceId}/invitations`, {
    invitation
  })
}

export const useInvitationService = (serviceId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendInvitationService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['listUsers', serviceId]
      })
    }
  })
}

const getCurrentUserProfile = (serviceId: string | undefined) => {
  return axiosPrivate.get(`/services/${serviceId}/profile`).then((response) => response.data.profile)
}

export const useGetProfile = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['profile', serviceId],
    queryFn: () => getCurrentUserProfile(serviceId)
  })
}

const listTags = (serviceId: string | undefined) => {
  return axiosPrivate.get(`/services/${serviceId}/tags`).then((response) => response.data.tags)
}

export const useListTags = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['service-tags'],
    queryFn: () => listTags(serviceId)
  })
}

const updateProfile = ({ serviceId, profile }: updateProfileProps) => {
  return axiosPrivate.put(`/services/${serviceId}/profile`, {
    profile
  })
}

export const useUpdateProfile = (serviceId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile', serviceId]
      })
    }
  })
}
