import { Modal } from 'react-bootstrap'
import { useServiceContext } from '../../hooks/useServiceContext'
import ActivityTimeline from '../../../../components/ActivityTimeline'

type ServiceSupportProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ServiceSupport = ({ setIsOpen }: ServiceSupportProps) => {
  const { service } = useServiceContext()

  return (
    <Modal show={true} onHide={() => setIsOpen(false)} size="lg">
      <Modal.Header className={'d-flex align-items-center justify-content-between border'}>
        <div className="fs-2 fw-bold d-flex align-items-center">
          <div>Centro de soporte al usuario</div>
        </div>
        <i className="bi bi-x-lg fs-1 cursor-pointer " onClick={() => setIsOpen(false)} />
      </Modal.Header>
      <Modal.Body className="px-10">
        <div className="row">
          <div className="col-12">
            <ActivityTimeline
              resourceType="services"
              resourceId={service?.id || ''}
              canRegisterEntry={true}
              canHandleSticky={true}
              placeholder="Describe tu consulta. Menciona a un miembro de tu equipo con @ o adjunta fotos y documentos."
              registerButtonMessage="Enviar consulta"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ServiceSupport
