import { Entry } from '../../activity.types'
import { RequirementsItemsAccepted } from '../automaticEntries/Requirements/RequirementsItemsAccepted'
import { ReservationsItemsDispatched } from '../automaticEntries/Reservations/ReservationsItemsDispatched'
import { SignaturesInvalidation } from '../automaticEntries/SignaturesInvalidation'
import { StockAdjustmentRestock } from '../automaticEntries/Stock/StockAdjustmentRestock'
import { RequirementsAcceptanceFiles } from '../automaticEntries/Requirements/RequirementsAcceptanceFiles'
import { RequirementsItemsReception } from '../automaticEntries/Requirements/RequirementsItemsReception'
import { RequirementsItemsRejected } from '../automaticEntries/Requirements/RequirementsItemsRejected'
import { RequirementsMove } from '../automaticEntries/Requirements/RequirementsMove'
import { ReservationsItemsReleased } from '../automaticEntries/Reservations/ReservationsItemsReleased'
import { ReservationsItemsReserved } from '../automaticEntries/Reservations/ReservationsItemsReserved'
import { ReservationsMove } from '../automaticEntries/Reservations/ReservationsMove'
import { StockWarehouseRelocated } from '../automaticEntries/Stock/StockWarehouseRelocated'
import { StockAdjustmentWithdrawal } from '../automaticEntries/Stock/StockAdjustmentWithdrawal'
import { DispatchGuidesDocumentEmitted } from '../automaticEntries/DispatchGuides/DispatchGuidesDocumentEmitted'

export const AutomaticEntry = ({ entry, canHaveTimeline }: { entry: Entry; canHaveTimeline: boolean }) => {
  switch (entry.action) {
    case 'requirements_items_accepted':
      return <RequirementsItemsAccepted entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'requirements_items_rejected':
      return <RequirementsItemsRejected entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'requirements_move':
      return <RequirementsMove entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'signatures_invalidation':
      return <SignaturesInvalidation entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'requirements_acceptance_files':
      return <RequirementsAcceptanceFiles entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'requirements_items_reception':
      return <RequirementsItemsReception entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'reservations_items_reserved':
      return <ReservationsItemsReserved entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'reservations_items_released':
      return <ReservationsItemsReleased entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'reservations_items_dispatched':
      return <ReservationsItemsDispatched entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'reservations_move':
      return <ReservationsMove entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'stocks_restock':
      return <StockAdjustmentRestock entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'stocks_withdrawal':
      return <StockAdjustmentWithdrawal entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'stocks_relocated':
      return <StockWarehouseRelocated entry={entry} canHaveTimeline={canHaveTimeline} />
    case 'dispatch_guides_document_emitted':
      return <DispatchGuidesDocumentEmitted entry={entry} canHaveTimeline={canHaveTimeline} />
    default:
      return <span>{entry.action}</span>
  }
}
