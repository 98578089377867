import { createContext, useEffect, useState } from 'react'
import { UnitsOfMeasureContextType } from '../../services.types'

export const UnitsOfMeasureContext = createContext<UnitsOfMeasureContextType>({
  unitsOfMeasure: [],
  unitsOfMeasureMap: {}
})

export const UnitsOfMeasureContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
  const [unitsOfMeasureMap, setUnitsOfMeasureMap] = useState({})

  useEffect(() => {
    const unitsOfMeasureMap = unitsOfMeasure.reduce((acc: any, unit: any) => {
      acc[unit.code] = unit.description
      return acc
    }, {})
    setUnitsOfMeasureMap(unitsOfMeasureMap)
  }, [unitsOfMeasure])

  return (
    <UnitsOfMeasureContext.Provider value={{ unitsOfMeasure, unitsOfMeasureMap, setUnitsOfMeasure }}>
      {children}
    </UnitsOfMeasureContext.Provider>
  )
}
