import { useEffect } from "react"
import { useAccessToken } from "./hooks/useAccessToken"

interface AuthInitProps {
  children: React.ReactNode
}

export const AuthInit = ({ children }: AuthInitProps): React.ReactElement => {
  const { loading, access } = useAccessToken()
  useEffect(() => {
    access()
  }, [])

  return loading ? <></> : <>{children}</>
}
