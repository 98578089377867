import { createContext, useState } from 'react'
import { FilterContext as FilterContextType } from '../../stock.types'
import { initialFilters } from '../components/TableAttributes'

export const FilterContext = createContext<FilterContextType | any>(null)

export const FilterContextProvider = ({ children }: any) => {
  const [filters, setFilters] = useState<FilterContextType>(initialFilters)

  return <FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
}
