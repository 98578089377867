import { User } from "../../features/invitations/invitation.types"
import UserAvatar from "./UserAvatar"

type UserDescProps = {
  user: User
}
export const UserDesc = ({ user }: UserDescProps) => {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <UserAvatar user={user} size={"40"} round={"30px"} />
      <div className="d-flex flex-column px-4">
        <span className="mb-0 fs-4">{user.name ?? "Usuario"}</span>
        <span className="text-secondary">{user.email}</span>
      </div>
    </div>
  )
}
