import React, { createContext, useReducer } from 'react'
import {
  ActionEnum,
  ActionType,
  AuthContextProviderProps,
  AuthContextType,
  AuthState,
} from '../auth.types'

export const initialAuthState: AuthState = {
  token: null,
}

export const AuthContext = createContext<AuthContextType>(initialAuthState)

const authReducer = (currentState: AuthState, action: ActionType): AuthState => {
  switch (action.type) {
    case ActionEnum.Login:
      localStorage.setItem('token', action.payload ?? '')
      return { token: action.payload }
    case ActionEnum.Logout:
      localStorage.removeItem('token')
      return { token: null }
    case ActionEnum.AuthIsReady:
      localStorage.setItem('token', action.payload ?? '')
      return { token: action.payload }
    default:
      return currentState
  }
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps): React.ReactElement => {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState)

  return <AuthContext.Provider value={{ ...authState, dispatch }}>{children}</AuthContext.Provider>
}
