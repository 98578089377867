import React from 'react'
import { RibbonBadge } from '../../features/invitations/components/RibbonBadge'
import EmptyUsers from '../../assets/images/undraw/empty-users.svg'
import { UserDesc } from './UserDesc'
import RoleUser from './RoleUser'
import { useAccountContext } from '../../setup/hooks/useAccountContext'
import { User } from './users.types'

interface ListUsersProps {
  users: User[] | undefined
  canDelete: boolean | undefined
  onDelete: (userId: number) => void
  hideTag?: boolean
  deleteSameUser?: boolean
}
export const ListUsers = ({ users, hideTag, onDelete, canDelete, deleteSameUser }: ListUsersProps) => {
  const { account } = useAccountContext()
  if (users?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
              <div>
                <img alt="404" src={EmptyUsers} className="h-200px" />

                <span className="fs-4 text-muted mt-10 d-block text-center">Sin usuarios asignados</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="h-sm-600px h-450px overflow-scroll w-100 position-relative">
      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4  ">
        <thead className="text-start text-dark-400 fw-bold fs-7 text-uppercase sticky-top">
          <tr className="text-start text-dark-400 fw-bold fs-7 text-uppercase bg-white">
            <th scope="col" className="px-0">
              Usuario
            </th>
            {!hideTag && (
              <th scope="col" className="px-4">
                Cargo
              </th>
            )}

            <th scope="col" className="px-4">
              Permiso
            </th>
            {canDelete && <th scope="col"></th>}
          </tr>
        </thead>
        <tbody className="fw-semibold text-dark-600 bg-white ">
          {users?.map(
            (user: User): React.ReactElement => (
              <tr className="even" key={user.email}>
                <td>
                  <UserDesc user={user} />
                </td>
                {!hideTag && (
                  <td>
                    <RoleUser role={user.tag ? user.tag.name : '--'} />
                  </td>
                )}
                <td>
                  <RibbonBadge role={user.role} />
                </td>
                {canDelete ? (
                  (user.email !== account?.email || deleteSameUser) && (
                    <td>
                      <button
                        className="border-0 p-4"
                        title="Remover usuario"
                        style={{ background: 'transparent' }}
                        onClick={() => onDelete(user.id)}
                      >
                        <i className="bi bi-trash fs-3 requirements-item-action-delete"></i>
                      </button>
                    </td>
                  )
                ) : (
                  <></>
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ListUsers
