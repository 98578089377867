import { useState } from 'react'
import { ServiceMenuRole } from '../ServiceMenuRole/ServiceMenuRole'
import { ServiceTeamModal } from '../ServiceMenuTeam/ServiceTeamModal'
import { useServiceContext } from '../../hooks/useServiceContext'
import ServiceSupport from '../ServiceSupport'

export const ServiceMenu = () => {
  const { service } = useServiceContext()
  const [isOpenModalParticipant, setIsOpenModalParticipant] = useState<boolean>(false)
  const [isOpenModalOcupation, setIsOpenModalOcupation] = useState<boolean>(false)
  const [isOpenModalSupport, setIsOpenModalSupport] = useState<boolean>(false)

  return (
    <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-8 gap-md-4 my-4 my-lg-0 portal__portal-border">
      <div className="d-flex flex-column align-items-start justify-content-center flex-wrap mx-5 mx-xl-8 ">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-30px symbol-circle me-2">
            <i className="bi bi-globe fs-2"></i>
          </div>
          <div className="m-0">
            <span className="text-secondary d-block fs-7">Organización</span>
            <span className="fw-bold text-gray-800 fs-6">{service?.organization.name}</span>
          </div>
        </div>
      </div>
      {service?.enterprise && (
        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap mx-5 mx-xl-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-30px symbol-circle me-2">
              <i className="bi bi-buildings fs-2"></i>
            </div>
            <div className="m-0">
              <span className="text-secondary d-block fs-7">Empresa</span>
              <span className="fw-bold text-gray-800 fs-6">{service?.enterprise.name}</span>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-column align-items-start justify-content-center flex-wrap position-relative mx-5 mx-xl-8 ">
        <div className="d-flex align-items-center cursor-pointer">
          <div
            className="symbol symbol-30px symbol-circle me-2 position-absolute w-100 h-100 d-flex align-items-center"
            onClick={() => setIsOpenModalOcupation(true)}
          >
            <i className="bi bi-person-badge fs-2 "></i>
          </div>
          <ServiceMenuRole serviceId={service?.id} isOpen={isOpenModalOcupation} setIsOpen={setIsOpenModalOcupation} />
        </div>
      </div>
      <div className="d-flex flex-column align-items-start justify-content-center flex-wrap mx-5 mx-xl-8 ">
        <div
          className="d-flex align-items-center text-secondary cursor-pointer service__team"
          title="Conoce tu equipo"
          onClick={() => setIsOpenModalParticipant(true)}
        >
          <div className="symbol symbol-30px symbol-circle me-2">
            <i className="bi bi-people fs-2 text-secondary"></i>
          </div>
          <div className="m-0 d-flex align-items-baseline">
            <span className="text-secondary  d-block fs-7">Equipo</span>
            <i className="bi bi-caret-down-fill ms-1"></i>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-start justify-content-center flex-wrap mx-5 mx-xl-8 ">
        <div
          className="d-flex align-items-center text-secondary cursor-pointer service__team"
          title="Conoce tu equipo"
          onClick={() => setIsOpenModalSupport(true)}
        >
          <div className="symbol symbol-30px symbol-circle me-2">
            <i className="bi bi-chat-fill fs-2 text-secondary"></i>
          </div>
          <div className="m-0 d-flex align-items-baseline">
            <span className="text-secondary  d-block fs-7">Soporte</span>
          </div>
        </div>
      </div>

      {isOpenModalParticipant && (
        <ServiceTeamModal
          title={'Miembros del equipo '}
          resourceId={service?.id}
          resourceType="services"
          canRegisterNewInvitation={service?.invitable}
          setIsOpen={setIsOpenModalParticipant}
          invitationTitle="Invitar un colaborador"
        />
      )}

      {isOpenModalSupport && <ServiceSupport setIsOpen={setIsOpenModalSupport} />}
    </div>
  )
}
