import { useQuery } from '@tanstack/react-query'
import { axiosMarketplace } from '../../../setup/axios'
import { useEffect, useState } from 'react'
import { useUnitsOfMeasureContext } from './useUnitsOfMeasureContext'

const fetchUnitOfMeasures = async () => {
  const response = await axiosMarketplace.get(`/units_of_measure`)
  return response.data.units_of_measure
}

export const useUnitsOfMeasureInit = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { setUnitsOfMeasure } = useUnitsOfMeasureContext()

  const { data: unitsOfMeasure } = useQuery({
    queryKey: ['marketplace', 'unitsOfMeasure'],
    queryFn: () => fetchUnitOfMeasures()
  })

  useEffect(() => {
    if (unitsOfMeasure && setUnitsOfMeasure) {
      setUnitsOfMeasure(unitsOfMeasure)
      setIsLoading(false)
    }
  }, [unitsOfMeasure])

  return { isLoading }
}
