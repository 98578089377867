import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'

type Entry = {
  id: string
  body: string
  resource_type: string
  resource_id: string
  service_id?: string
}
type ListEntriesProps = {
  resourceType: string
  resourceId: string
  serviceId?: string
}
type DeleteEntryProps = {
  entryId: string
  attachmentId: string
}
type AttachmentEntryProps = {
  id: string
  config?: any
  formData: FormData
}

const createEntry = (entry: Entry) => {
  return axiosPrivate.post(`/activity/entries`, {
    entry
  })
}

export const useCreateEntry = ({ resourceType, resourceId }: ListEntriesProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createEntry,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['entries', resourceType, resourceId]
      })
    }
  })
}

const handleSticky = ({ entryId, serviceId }: { entryId: string; serviceId: string | undefined }) => {
  return axiosPrivate.put(`/activity/entries/${entryId}`, {
    entry: { service_id: serviceId }
  })
}

export const useHandleSticky = ({ resourceType, resourceId }: { resourceType: string; resourceId: string }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: handleSticky,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['entries', resourceType, resourceId]
      })
    }
  })
}

const getListEntries = ({ resourceType, resourceId, serviceId }: ListEntriesProps) => {
  let params = { resource_type: resourceType, resource_id: resourceId, service_id: serviceId }
  return axiosPrivate.get(`/activity/entries`, { params }).then((res) => {
    return res.data.entries
  })
}

export const useGetListEntries = ({ resourceType, resourceId, serviceId }: ListEntriesProps) => {
  return useQuery({
    queryKey: ['entries', resourceType, resourceId],
    queryFn: () => getListEntries({ resourceType, resourceId, serviceId })
  })
}

const createAttachmentEntry = ({ id, config, formData }: AttachmentEntryProps) => {
  return axiosPrivate.post(`/activity/entries/${id}/attachments`, formData, config)
}

export const useCreateAttachment = () => {
  return useMutation({ mutationFn: createAttachmentEntry })
}

const deleteAttachment = ({ entryId, attachmentId }: DeleteEntryProps) => {
  return axiosPrivate.delete(`/activity/entries/${entryId}/attachments/${attachmentId}`)
}

export const useDeleteAttachment = () => {
  return useMutation({ mutationFn: deleteAttachment })
}
