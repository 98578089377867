export const roleLabel = (role: string): string => {
  let label = role
  switch(role) {
    case 'admin':
      label = 'Administrador'
      break
    case 'responsible':
      label = 'Responsable'
      break
    case 'participant':
      label = 'Participante'
      break
    case 'notification_receiver':
      label = 'Receptor de Notificaciones'
      break
  }

  return label
}
