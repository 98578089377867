import { Outlet } from "react-router-dom"
import { AccountInit } from "../features/account/AccountInit"
import { HeaderWrapper } from "./ApplicationPartials/header/HeaderWrapper"
import { AsideDefault } from "./ApplicationPartials/sidebar/AsideDefault"
import { MenuContextProvider } from "../components/menu/MenuContext"
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb"

const ApplicationLayout = (): React.ReactElement => {
  return (
    <AccountInit>
      <MenuContextProvider>
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed">
          <div className="page d-flex flex-row flex-column-fluid">
            <AsideDefault />
            <div className="wrapper d-flex flex-column flex-row-fluid">
              <HeaderWrapper />
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="content-breadcrumbs mb-2 px-5">
                  <Breadcrumb />
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </MenuContextProvider>
    </AccountInit>
  )
}

export default ApplicationLayout
