import { useContext } from 'react'
import { MenuContextType } from './menu.types'
import { MenuContext } from './MenuContext'

export const useMenuContext = (): MenuContextType => {
  const context = useContext(MenuContext)

  if (!context) {
    throw Error('useMenuContext must be inside an AccountContextProvider')
  }

  return context
}
