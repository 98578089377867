export const formatSelect = (option: string) => {
  if (option === 'restock') return 'Ingresar stock'
  if (option === 'withdrawal') return 'Retirar stock'
  return 'Aceptar'
}

export const selectStyleHeader = {
  control: (provided: any) => ({
    ...provided,
    height: '45px',
    width: '150px',
    position: 'relative',
    zIndex: '0',
    background: 'transparent',
    minHeight: 'initial',
    boxShadow: 'none',
    borderColor: '#ccc',
    '&:hover': {
      borderColor: '#ccc'
    }
  })
}

export const RESERVATION_MODULES = ['dispatches', 'returns', 'provisions']

export const RESERVATION_TITLES = {
  dispatches: 'Despacho de materiales',
  returns: 'Devolución de materiales',
  provisions: 'Reserva de materiales'
}

export const RESERVATION_MODULE_NAMES = {
  dispatches: 'Despacho',
  returns: 'Devoluciones',
  provisions: 'Reservas'
}

export const RESERVATION_MODULE_ICONS = {
  dispatches: 'bi-truck-flatbed',
  returns: 'bi-truck-flatbed',
  provisions: 'bi-truck-flatbed'
}

export const DISPATCH_GUIDE_TITLES = {
  registry: 'Registro de guías',
  delivery: 'Envío de guías',
  issuance: 'Generación de guías'
}
