import moment from 'moment'
import { Entry } from '../../../activity.types'

export const StockWarehouseRelocated = ({ entry, canHaveTimeline }: { entry: Entry; canHaveTimeline: boolean }) => {
  let { source_location, source_warehouse, target_location, target_warehouse } = entry.config

  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-primary">
        <i className="fs-3 bi text-white activity__icon bi-arrows-move"></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <div className={'activity__text ' + (entry.body !== '' ? 'd-flex flex-column' : '')}>
            <span className="me-2">
              <span className="activity__span fw-bold">{entry.user.name}</span>movió el stock desde{' '}
              <span className="fst-italic fw-bold">
                {source_warehouse} ({source_location})
              </span>{' '}
              hacia{' '}
              <span className="fst-italic fw-bold">
                {target_warehouse} ({target_location})
              </span>
            </span>
            {entry.body !== '' && <div className="bg-light-primary p-4 my-4 min-w-200px">{entry.body}</div>}

            <span className="activity__date text-secondary fs-7 d-inline-block">
              {moment(entry.created_at).format('DD MMM h:mm A')}
            </span>
          </div>
        </div>

        <div className="activity__body me-3 pb-2 border-bottom border-1"></div>
      </div>
    </div>
  )
}
