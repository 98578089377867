import { useContext } from 'react'
import { UnitsOfMeasureContextType } from '../../services.types'
import { UnitsOfMeasureContext } from '../contexts/UnitsOfMeasureContext'

export const useUnitsOfMeasureContext = (): UnitsOfMeasureContextType => {
  const context = useContext(UnitsOfMeasureContext)

  if (!context) {
    throw Error('useUnitsOfMeasureContext must be inside an useUnitsOfMeasureContextProvider')
  }

  return context
}
