import { createContext, useState } from 'react'
import { MenuContextType, MenuContextProviderProps } from './menu.types'

export const MenuContext = createContext<MenuContextType>({
  visible: false,
  setVisible: () => {},
})

export const MenuContextProvider = ({
  children,
}: MenuContextProviderProps): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <MenuContext.Provider value={{ visible, setVisible }}>
      {children}
    </MenuContext.Provider>
  )
}
