import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import UserAvatar from '../../../../components/users/UserAvatar'
import { useAccountContext } from '../../../../setup/hooks/useAccountContext'
import TextNotification from '../../../TextNotification'
import { NotificationProps } from '../../../TextNotification/notification.types'
import { useCreateEntry, useDeleteAttachment } from '../../hook/useActivity'
import { toast } from 'react-hot-toast'
import { FilesProps } from '../../activity.types'
import { EntryInput } from './EntryInput'
import Dropzone from 'react-dropzone'
import { PendingAttachment } from '../attachment/PendingAttachment'
import { useServiceContext } from '../../../../Services/ServicesShow/hooks/useServiceContext'

type EntryInputProps = {
  resourceId: string
  resourceType: string
  placeholder?: string
  registerButtonMessage?: string
}
const MAX_FILES = 3
const MAX_FILE_SIZE = 5000000 // 5MB

export const EntryManagement = ({ resourceId, resourceType, placeholder, registerButtonMessage }: EntryInputProps) => {
  const { account } = useAccountContext()
  const { service } = useServiceContext()

  const [uuid, setUuid] = useState<string>(uuidv4())
  const [comment, setComment] = useState<string>('')
  const [numLetters, setNumLetters] = useState<number>(0)
  const [files, setFiles] = useState<FilesProps[]>([])
  const [listNotification, setListNotification] = useState<NotificationProps[]>([])
  const [highlight, setHighlight] = useState<boolean>(false)

  const { mutate: createEntry, isPending: creatingEntry } = useCreateEntry({
    resourceId,
    resourceType
  })
  const { mutate: deleteAttachment, isPending: deletingEntry } = useDeleteAttachment()

  // SEND COMMENT
  const handleSendComment = () => {
    if (creatingEntry) {
      return
    }
    createEntry(
      {
        id: uuid,
        body: comment,
        resource_id: resourceId,
        resource_type: resourceType,
        service_id: service?.id
      },
      {
        onSuccess: () => {
          toast.success('Comentario creado exitosamente')
          setComment('')
          setFiles([])
          setUuid(uuidv4())
          setNumLetters(0)
        },
        onError: () => {
          toast.error('Error al crear comentario')
        }
      }
    )
  }
  // DELETE ATTACHMENT
  const handleDeleteAttachment = (fileDelete: FilesProps) => {
    if (deletingEntry) return
    deleteAttachment(
      { entryId: uuid, attachmentId: fileDelete.id },
      {
        onSuccess: () => {
          setFiles((files) => files.filter((file: FilesProps) => file.id !== fileDelete.id))
        },
        onError: () => {
          setListNotification([
            ...listNotification,
            {
              id: uuid,
              isSuccess: false,
              text: 'No se pudo eliminar la imagen'
            }
          ])
        }
      }
    )
  }

  // CREATE ATTACHMENT
  const handleFileChange = (event: any) => {
    filesUpload(event.target.files)
  }

  const onDropable = (acceptedFiles: File[]) => {
    setHighlight(false)
    filesUpload(acceptedFiles)
  }

  const filesUpload = (listFiles: any) => {
    const len = listFiles.length
    if (files.length + len > MAX_FILES) {
      setListNotification([
        ...listNotification,
        {
          id: 1,
          isSuccess: false,
          text: 'Solo se permite subir 3 archivos'
        }
      ])
      return
    }
    const filesArray = [...listFiles]
    // check the size of images
    filesArray.forEach((file: any) => {
      if (file.size > MAX_FILE_SIZE) {
        setListNotification([
          ...listNotification,
          {
            id: 1,
            isSuccess: false,
            text: 'El archivo es demasiado grande'
          }
        ])
        return
      }
    })
    // give a new format to the files
    const fileList = filesArray.map((file: any) => {
      return { id: uuidv4(), file: file }
    })

    setFiles((oldFiles) => [...oldFiles, ...fileList])
  }

  return (
    <div className="">
      <div className="d-flex">
        <UserAvatar user={account} size="30" round={true} />
        <div className="activity__header">
          <span className="mx-2 activity__span text-success">{account?.name}</span>
        </div>
      </div>

      <div className="my-2">
        <Dropzone onDrop={(acceptedFiles) => onDropable(acceptedFiles)} onDragOver={() => setHighlight(true)}>
          {({ getRootProps }) => (
            <>
              <div
                {...getRootProps({
                  onClick: (event: any) => event.stopPropagation()
                })}
                className={highlight ? 'activity__dragDrop rounded px-4 py-4' : ''}
              >
                <EntryInput
                  comment={comment}
                  setComment={setComment}
                  handleFileChange={handleFileChange}
                  numLetters={numLetters}
                  setNumLetters={setNumLetters}
                  placeholder={placeholder}
                />
              </div>
              <div className={(files.length ? 'mt-2 shadow-sm' : '') + ' d-flex'}>
                {files.map((file: FilesProps) => {
                  return (
                    <PendingAttachment
                      key={file.id}
                      entryUuid={uuid}
                      file={file}
                      resourceId={resourceId}
                      resourceType={resourceType}
                      handleDelete={handleDeleteAttachment}
                    />
                  )
                })}
              </div>
            </>
          )}
        </Dropzone>

        <div className="w-100 d-flex justify-content-between align-items-center mt-2">
          <TextNotification setListNotification={setListNotification} notificationList={listNotification} />
          <button
            className="btn btn-success btn-sm"
            onClick={handleSendComment}
            disabled={!(numLetters > 0 || files.length > 0)}
          >
            {registerButtonMessage || 'Enviar comentario'}
          </button>
        </div>
      </div>
    </div>
  )
}
