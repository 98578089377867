import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import axios from '../../../setup/axios'

export const useResetPassword = (): {
  loading: boolean
  checkResetToken: (token: string) => Promise<void>
  resetPassword: (token: string, newPassword: string) => Promise<void>
  hasErrors: boolean
} => {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const navigate = useNavigate()

  const checkResetToken = async (token: string): Promise<void> => {
    if (loading) return
    setLoading(true)
    setHasErrors(false)

    try {
      await axios.get(`/auth/password_resets/${token}`)
      setLoading(true)
      setHasErrors(false)
    } catch (error) {
      // We should differentiate between different errors.
      // We should throw an error in case of bad client credentials
      setLoading(false)
      setHasErrors(true)
    }
  }

  const resetPassword = async (token: string, newPassword: string): Promise<void> => {
    if (loading) return
    setLoading(true)
    setHasErrors(false)

    try {
      await axios.put(`/auth/password_resets/${token}`, {
        password_reset: { new_password: newPassword },
      })

      toast.success('Contraseña cambiada, ingresa tus credenciales.', {
        duration: 4000,
      })
      navigate('/auth')
    } catch (error) {
      // We should differentiate between different errors.
      // We should throw an error in case of bad client credentials
      setLoading(false)
      setHasErrors(true)
    }
  }

  return { loading, checkResetToken, resetPassword, hasErrors }
}
