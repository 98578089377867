import { Link, Outlet } from 'react-router-dom'
import AuthLogo from '../assets/images/auth_logo_white.svg'
import Background from '../assets/images/auth_background.png'

const AuthLayout = (): React.ReactElement => {
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundColor: `#1e1e2d`,
        backgroundImage: `url(${Background})`,
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid m-5">
        <div className="mb-12">
          <Link to="/auth">
            <img alt="Logo" src={AuthLogo} className="h-75px h-md-85px" />
          </Link>
        </div>
        <div className="w-lg-500px w-100 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mb-20">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
