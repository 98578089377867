import { createContext, useState } from 'react'
import { Material, MaterialContextProviderProps, MaterialContextType } from '../../materials.types'

export const MaterialContext = createContext<MaterialContextType>({
  material: null,
  setMaterial: () => {}
})

export const MaterialContextProvider = ({ children }: MaterialContextProviderProps) => {
  const [material, setMaterial] = useState<Material | null>(null)

  return <MaterialContext.Provider value={{ material, setMaterial }}>{children}</MaterialContext.Provider>
}
