import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'
import { useServiceContext } from './useServiceContext'
import { useEffect, useState } from 'react'

const fetchService = async (id: string | undefined) => {
  const response = await axiosPrivate.get(`/services/${id}`)
  return response.data.service
}

export const useServiceInit = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const { setService } = useServiceContext()

  const { data: service } = useQuery({
    queryKey: ['service', id],
    queryFn: () => fetchService(id)
  })

  useEffect(() => {
    if (service && setService) {
      setIsLoading(false)
      setService(service)
    }
  }, [service, setService])

  return { isLoading }
}
