import { useState, useCallback } from 'react'
import { axiosPrivate } from '../../../setup/axios'
import { useAccountContext } from '../../../setup/hooks/useAccountContext'

export const useAccountInit = (): {
  loading: boolean
  initialize: () => Promise<void>
} => {
  const [loading, setLoading] = useState<boolean>(true)
  const { setAccount } = useAccountContext()

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const response = await axiosPrivate.get('/account')
      if (setAccount != null) {
        setAccount(response.data.account)
      }

      setLoading(false)
    } catch (error) {

      setLoading(false)
    }
  }, [])

  return { loading, initialize }
}
