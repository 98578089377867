import { createContext, useState } from 'react'
import {
  Requirement,
  RequirementContextProviderProps,
  RequirementContextType,
} from '../../requirements.types'

export const RequirementContext = createContext<RequirementContextType>({
  requirement: null,
})

export const RequirementContextProvider = ({ children }: RequirementContextProviderProps) => {
  const [requirement, setRequirement] = useState<Requirement | null>(null)

  return (
    <RequirementContext.Provider value={{ requirement, setRequirement }}>
      {children}
    </RequirementContext.Provider>
  )
}
