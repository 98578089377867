import moment from 'moment'
import { Entry } from '../../../activity.types'

export const ReservationsMove = ({
  entry,
  canHaveTimeline,
}: {
  entry: Entry
  canHaveTimeline: boolean
}) => {
  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-primary">
        <i
          className="fs-4 bi text-white ps-0 bi-box-arrow-in-right activity__icon-box"
          style={{ paddingTop: '0px', paddingRight: '2px' }}
        ></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <p className="activity__text">
            <span className="me-2">
              <span className="activity__span fw-bold">{entry.user.name}</span>
              ha trasladado la reserva desde
              <span className="fst-italic mx-1">{entry.config.initial_column}</span>a
              <span className="fst-italic mx-1">{entry.config.next_column}</span>
            </span>
            <span className="activity__date text-secondary fs-7 d-inline-block">
              {moment(entry.created_at).format('DD MMM h:mm A')}
            </span>
          </p>
        </div>
        <div className="activity__body me-3 pb-2 border-bottom border-1"></div>
      </div>
    </div>
  )
}
