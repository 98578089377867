import { components, DropdownIndicatorProps } from "react-select"
import UserAvatar from "../../../components/users/UserAvatar"
import { validateEmail } from "../../../helpers/invitationsHelpers"

export const isOptionDisabled = (option: any): boolean => {
  // (option.value || '') solves case where user found by email but name is not completed
  return !option.name && !option.email && !(option.value || "").includes("@")
}

export const DropdownIndicator = (props: DropdownIndicatorProps<any, true>): React.ReactElement => {
  return !props.hasValue ? (
    <components.DropdownIndicator {...props}>
      <i className="bi bi-search fs-4"></i>
    </components.DropdownIndicator>
  ) : (
    <></>
  )
}

export const IndicatorSeparator = (): React.ReactElement => {
  return <></>
}

// Component that renders dropdown options
export const FormatOptionLabel = (props: any): React.ReactElement => {
  if (props.__isNew__ === true) {
    if (validateEmail(props.value)) {
      return (
        <div className="d-flex flex-stack py-2">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-40px ">
              <span className="svg-icon svg-icon-2">
                <i className="bi bi-envelope fs-2"></i>
              </span>
            </div>
            <div className="ms-4">
              <p className="fs-7 fw-bold text-gray-900 mb-1">{props.value}</p>
              <div className="fs-7 text-muted">Invitar colaborador</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-center">
          <div className="fs-7 text-dark">
            Si no encontraste el usuario ingresa un <strong>email</strong> completo
          </div>
        </div>
      )
    }
  }

  return (
    <div className="d-flex flex-stack py-4 disabled">
      <div className="d-flex align-items-center">
        <div className="symbol symbol-40px symbol-circle">
          <UserAvatar user={props} size={"30"} round={true} />
        </div>
        <div className="ms-4">
          <p className="fs-7 fw-bold text-gray-900 mb-1">{props.name}</p>
          <div className="fs-7 text-muted">{props.email}</div>
        </div>
      </div>
    </div>
  )
}
//
export const FormatOptionLabelToSyncSelect = (props: any): React.ReactElement => {
  if (props.__isNew__ === true) {
    if (validateEmail(props.value)) {
      return (
        <div className="d-flex flex-stack py-2">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-40px ">
              <span className="svg-icon svg-icon-2">
                <i className="bi bi-envelope fs-2"></i>
              </span>
            </div>
            <div className="ms-4">
              <p className="fs-7 fw-bold text-gray-900 mb-1">{props.value}</p>
              <div className="fs-7 text-muted">Invitar colaborador</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-center">
          <div className="fs-7 text-dark">
            Si no encontraste el usuario ingresa un <strong>email</strong> completo
          </div>
        </div>
      )
    }
  }

  return (
    <div className="d-flex flex-stack py-4 disabled">
      <div className="d-flex align-items-center">
        <div className="symbol symbol-40px symbol-circle">
          <UserAvatar user={props.more} size={"30"} round={true} />
        </div>
        <div className="ms-4">
          <p className="fs-7 fw-bold text-gray-900 mb-1">{props.more.name}</p>
          <div className="fs-7 text-muted">{props.more.email}</div>
        </div>
      </div>
    </div>
  )
}
