import React from 'react'
import { Link } from 'react-router-dom'
import { NavigationLinkType } from './breadcrumb.types'

interface NavigationLinksProps {
  links: NavigationLinkType[] | undefined
}

export const NavigationLinks = ({ links }: NavigationLinksProps): React.ReactElement => {
  if (links?.length === 0) {
    return <></>
  }

  return (
    <ul className="breadcrumb fw-semibold fs-7 mt-1">
      {links?.map((link, i) => {
        return (
          <li key={i} className="breadcrumb-item text-muted cursor-none">
            {link.url != null ? (
              <Link to={link.url} className="link-primary">
                {link.title} &nbsp;
              </Link>
            ) : (
              <>{link.title}</>
            )}
          </li>
        )
      })}
    </ul>
  )
}
