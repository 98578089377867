import { useEffect, useRef } from 'react'
import moment from 'moment'
import UserAvatar from '../../../../components/users/UserAvatar'
import { Entry } from '../../activity.types'
import { AttachmentManagement } from '../attachment/AttachmentManagement'
import { useHandleSticky } from '../../hook/useActivity'
import { useServiceContext } from '../../../../Services/ServicesShow/hooks/useServiceContext'
import { useLocation } from 'react-router-dom'

export const ManualEntry = ({
  entry,
  canHaveTimeline,
  canHandleSticky,
  resourceId,
  resourceType
}: {
  entry: Entry
  canHaveTimeline: boolean
  canHandleSticky: boolean
  resourceType: string
  resourceId: string
}) => {
  const { service } = useServiceContext()
  const { mutate: updateSticky, isPending } = useHandleSticky({ resourceType, resourceId })

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const commentId = searchParams.get('comment')

  const entryRef = useRef<HTMLDivElement>(null)

  const handleSticky = () => {
    if (isPending) return

    updateSticky({ entryId: entry.id, serviceId: service?.id })
  }

  useEffect(() => {
    if (commentId === entry.id && entryRef.current) {
      entryRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [commentId, entry.id])

  return (
    <div className="position-relative activity__timelineItem " id={entry.id} ref={entryRef}>
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="activity__timelineIcon w-auto">
        <UserAvatar user={entry.user} size="30" round={true} />
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header d-flex justify-content-between">
          <div className="d-flex flex-grow-1">
            <div className="activity__span fw-bold mx-2">{entry.user.name}</div>
            <div className="activity__date text-secondary fs-7">{moment(entry.created_at).format('DD MMM h:mm A')}</div>
          </div>
          {canHandleSticky && (
            <div className="text-end cursor-pointer d-none sticky-icon" onClick={handleSticky}>
              {entry.sticky ? (
                <i className="bi bi-award-fill fs-4 text-mina365-secondary px-2" title="Quitar fijado" />
              ) : (
                <i className="bi bi-award fs-4 text-mina365-secondary px-2" title="Fijar arriba" />
              )}
            </div>
          )}
        </div>
        <div className="activity__body mx-2 mt-2 pb-5 border-bottom border-1">
          <div className={`${commentId === entry.id ? 'activity__body-highlight' : ''}`}>
            {entry.body.split(/(@\[\w+\s*\w*\]\(\d+\))/g).map((part, index) => {
              if (part.match(/@\[(.*?)\]\(\d+\)/)) {
                const match = (part || '').match(/@\[(.*?)\]\(\d+\)/)
                const name = match ? match[1] : ''
                return (
                  <span key={index} className="mention">
                    @{name}
                  </span>
                )
              } else {
                return part
              }
            })}
          </div>

          {entry.attachments.length !== 0 && <AttachmentManagement attachments={entry.attachments} />}
        </div>
      </div>
    </div>
  )
}
