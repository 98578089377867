import { createContext, useState } from 'react'
import ConfirmationModal from '..'

export const ConfirmationModalContext = createContext<any>({})
export const ConfirmationModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalConfig, setModalConfig] = useState<any>({})

  const openModal = ({
    title,
    message,
    theme,
    action,
    actionCallback,
  }: {
    title: string
    message: string
    theme?: string
    action?: string
    actionCallback: any
  }) => {
    setModalOpen(true)
    setModalConfig({ title, message, action, theme, actionCallback })
  }

  const resetModal = () => {
    setModalOpen(false)
  }
  const onConfirm = () => {
    modalConfig.actionCallback(true)
    resetModal()
  }
  const onDismiss = () => {
    modalConfig.actionCallback(false)
    resetModal()
  }

  return (
    <ConfirmationModalContext.Provider value={{ openModal }}>
      <ConfirmationModal
        isOpen={modalOpen}
        title={modalConfig.title}
        message={modalConfig.message}
        action={modalConfig.action || 'Continuar'}
        theme={modalConfig.theme || 'warning'}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationModalContext.Provider>
  )
}
