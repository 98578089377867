import moment from 'moment'
import { Entry } from '../../../activity.types'
import { formatQuantity } from '../../../../utils'

export const ReservationsItemsDispatched = ({ entry, canHaveTimeline }: { entry: Entry; canHaveTimeline: boolean }) => {
  const completionMessage =
    entry.config.dispatched_quantity === 1
      ? `${formatQuantity(entry.config.dispatched_quantity)} material`
      : `${formatQuantity(entry.config.dispatched_quantity)} materiales`

  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-primary">
        <i className="fs-3 bi text-white activity__icon bi-cart-check"></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <p className="activity__text">
            <span className="me-2">
              <span className="activity__span fw-bold">{entry.user.name}</span>ha despachado {completionMessage}
            </span>
            <span className="activity__date text-secondary fs-7 d-inline-block">
              {moment(entry.created_at).format('DD MMM h:mm A')}
            </span>
          </p>
        </div>
        <div className="activity__body me-3 pb-2 border-bottom border-1"></div>
      </div>
    </div>
  )
}
