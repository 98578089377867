import { versionImage } from '../../../../helpers/imageHelper'
import { FileActivity } from '../../activity.types'
import { AttachmentPreview } from './AttachmentPreview'

type AttachmentItemProps = {
  attachment: FileActivity
}
export const AttachmentItem = ({ attachment }: AttachmentItemProps) => {
  // I  have version lightbox, on image click it should open. Currently it is not implemented.

  return (
    <div title={attachment.filename} className="activity__attachment position-relative me-3 my-1 border-light">
      <div className="d-flex justify-content-center align-items-center h-100">
        <AttachmentPreview
          extension={attachment.extension}
          version={versionImage(attachment.versions, attachment.processed, 'thumbnail')}
        />
      </div>
      <div className="position-absolute bottom-0 start-0 d-flex align-items-center bg-white py-1 px-2 w-100">
        <span className="text-secondary fs-8 activity__attachmentText">{attachment.filename}</span>
      </div>
      <a
        href={attachment.versions.default}
        download={attachment.filename}
        target={'_blank'}
        rel="noreferrer"
        className="position-absolute top-0 start-0 w-100 h-100 activity__attachmentUrl"
      >
        <i className="position-absolute bi bi-box-arrow-in-down fs-6 activity__attachmentIcon--dowload text-success"></i>
      </a>
    </div>
  )
}
