import { HeaderUserMenu } from './HeaderUserMenu'

export const Topbar = (): React.ReactElement => {
  return (
    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
      {/* begin::Navbar This are links from demo 4 */}
      <div className="d-flex align-items-stretch"></div>
      {/* end::Navbar */}

      <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
        <HeaderUserMenu />
      </div>
    </div>
  )
}
