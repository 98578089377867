import { useEffect, useState } from 'react'
import { useLastReservationContext } from '../../../Reservations/ReservationCart/hooks/useLastReservationContext'
import { useServiceContext } from '../../../Services/ServicesShow/hooks/useServiceContext'
import { useGetLastReservation } from '../../../Reservations/ReservationCart/hooks/useReservationCart'

export const useStockInit = (): {
  loading: boolean
} => {
  const { service } = useServiceContext()
  const [loading, setLoading] = useState<boolean>(true)
  const { setLastReservation, setLoading: loadingReservation } = useLastReservationContext()
  const { data: lastReservation, isError } = useGetLastReservation(service?.id)

  useEffect(() => {
    if (loadingReservation) {
      if (setLastReservation && lastReservation) setLastReservation(lastReservation)
      loadingReservation(false)
    }
    if (setLastReservation && isError) {
      setLastReservation(null)
    }
    setLoading(false)
  }, [lastReservation, isError, loadingReservation, setLastReservation])
  return { loading }
}
