import React from 'react'
import { AsideMenuMain } from './AsideMenuMain'

const AsideMenu = (): React.ReactElement => {
  return (
    <div className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1">
      <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold">
        <AsideMenuMain />
      </div>
    </div>
  )
}

export default AsideMenu
