import React, { useCallback, useEffect } from 'react'
import { NotificationProps } from './notification.types'

type TextNotificationProps = {
  notificationList: NotificationProps[]
  setListNotification: React.Dispatch<React.SetStateAction<any[]>>
}

const TextNotification = ({
  notificationList,
  setListNotification,
}: TextNotificationProps) => {
  const deleteNotification = useCallback(
    (id: any) => {
      const notificationListItem = notificationList.filter((e: any) => e.id !== id)
      setListNotification(notificationListItem)
    },
    [notificationList, setListNotification]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (notificationList.length) {
        deleteNotification(notificationList[0].id)
      }
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [notificationList, deleteNotification])

  return (
    <div className="mx-4">
      {notificationList.map((notification: NotificationProps, i: number) => (
        <div className="d-flex align-items-center justify-content-center" key={i}>
          <i
            className={
              'bi fs-1 ' + (notification.isSuccess ? 'bi-check text-success' : 'bi-x text-danger')
            }
          ></i>
          <p className={'mb-0 ' + (notification.isSuccess ? 'text-success' : 'text-danger')}>
            {notification.text}
          </p>
        </div>
      ))}
    </div>
  )
}

export default TextNotification
