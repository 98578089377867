import { useStockInit } from '../hooks/useStockInit'

type StockInitProps = {
  children: React.ReactNode
}
export const StockInit = ({ children }: StockInitProps): React.ReactElement => {
  const { loading } = useStockInit()

  if (loading) {
    return <></>
  }

  return <>{children}</>
}
