import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import axios from '../../../setup/axios'

export const useForgotPassword = (): {
  loading: boolean
  sendResetInstructions: (email: string) => Promise<void>
  hasErrors: boolean
} => {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const navigate = useNavigate()

  const sendResetInstructions = async (email: string): Promise<void> => {
    setLoading(true)
    setHasErrors(false)

    try {
      await axios.post('/auth/password_resets', {
        password_reset: { email },
      })

      toast.success('Enviamos las intrucciones a tu email', { duration: 4000 })
      navigate('/auth')
    } catch (error) {
      // We should differentiate between different errors.
      // We should throw an error in case of bad client credentials
      setLoading(false)
      setHasErrors(true)
    }
  }

  return { loading, sendResetInstructions, hasErrors }
}
