export const Topbar = (): React.ReactElement => {
  const appUrl = import.meta.env.VITE_REACT_APP_URL

  return (
    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
      {/* begin::Navbar This are links from demo 4 */}
      <div className="d-flex align-items-stretch"></div>
      {/* end::Navbar */}

      <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <a
            href={'https://mina365.com/registro-mercado-minero'}
            className="btn btn-outline btn-sm btn-outline-secondary btn-active-light-secondary text-secondary d-flex align-items-center align-items-center"
          >
            <div>Regístrate</div>
          </a>
        </div>

        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <a
            href={appUrl}
            className="btn btn-sm btn-outline btn-outline-secondary btn-active-light-secondary text-secondary d-flex align-items-center align-items-center"
          >
            <i className="bi bi-arrow-bar-right text-secondary fs-6 "></i>
            <div>Ingresa</div>
          </a>
        </div>
      </div>
    </div>
  )
}
