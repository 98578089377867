import { useEffect } from 'react'
import { useAccountInit } from './hooks/useAccountInit'

interface AccountInitProps {
  children: React.ReactNode
}

export const AccountInit = ({
  children,
}: AccountInitProps): React.ReactElement => {
  const { loading, initialize } = useAccountInit()

  useEffect(() => {
    initialize()
  }, [initialize])

  if(loading) {
    return <></>
  }

  return <>{children}</>
}
