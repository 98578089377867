import axios, { axiosPrivate } from '../../../setup/axios'
import { useAuthContext } from '../../../setup/hooks/useAuthContext'
import { ActionEnum } from '../../../setup/auth.types'

export const useLogout = (): {
  logout: () => Promise<void>
} => {
  const { token, dispatch } = useAuthContext()

  const logout = async (): Promise<void> => {
    try {
      delete axiosPrivate.defaults.headers.common.Authorization

      await axios.post(
        '/oauth/revoke',
        {
          token,
          client_id: import.meta.env.VITE_REACT_APP_CLIENT_ID,
          client_secret: import.meta.env.VITE_REACT_APP_CLIENT_SECRET,
        },
        { withCredentials: true }
      )
      if (dispatch != null) {
        dispatch({
          type: ActionEnum.Logout,
          payload: null,
        })
      }
    } catch (error) {
      // Endpoint allways returns 200, maybe hable other errors
    }
  }

  return { logout }
}
