import ContentLoader from "react-content-loader"

export const ListParticipantLoader = () => {
  return (
    <ContentLoader speed={2} width={"100%"} height={500}>
      <rect x="10" y="10" rx="4" ry="4" width="100%" height="50" />
      <rect x="10" y="70" rx="4" ry="4" width="100%" height="50" />
      <rect x="10" y="130" rx="4" ry="4" width="100%" height="50" />
    </ContentLoader>
  )
}
