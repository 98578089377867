import { createContext, useState } from 'react'
import { Reservation, ReservationContextProviderProps, ReservationContextType } from '../../reservations.types'
export const ReservationContext = createContext<ReservationContextType>({
  reservation: null,
  errors: {},
  setErrors: () => {},
  clearErrors: () => {}
})

export const ReservationContextProvider = ({ children }: ReservationContextProviderProps) => {
  const [reservation, setReservation] = useState<Reservation | null>(null)
  const [errors, setErrors] = useState({})

  const clearErrors = () => {
    setErrors({})
  }

  return (
    <ReservationContext.Provider
      value={{
        reservation,
        setReservation,
        errors,
        setErrors,
        clearErrors
      }}
    >
      {children}
    </ReservationContext.Provider>
  )
}
