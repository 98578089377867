import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { RequirementContextProvider } from '../../Requirements/RequirementsShow/contexts/RequirementContext'
import { ConfirmationModalProvider } from '../../components/ConfirmationModal/contexts/ConfirmationModalContext'
import SuspensedView from '../../components/SuspensedView'
import { ServiceContextProvider } from '../ServicesShow/contexts/ServiceContext'
import { ServiceInit } from '../ServicesShow/layouts/ServiceInit'
import { ServiceLayout } from '../ServicesShow/layouts/ServiceLayout'
import { LastReservationContextProvider } from '../../Reservations/ReservationCart/contexts/LastReservationContext'
import { ReservationContextProvider } from '../../Reservations/ReservationsShow/contexts/ReservationContext'
import { DispatchGuideContextProvider } from '../../DispatchGuides/Show/contexts/DispatchGuideContext'
import { MaterialContextProvider } from '../../Materials/Show/contexts/MaterialContext'
import { TablePropsContextProvider } from '../../Requirements/RequirementsIndexTable/contexts/TablePropsContext'
import { StockInit } from '../../Stock/StockIndexTable/components/StockInit'
import { FilterContextProvider } from '../../Stock/StockIndexTable/contexts/FilterContext'
import { ReservationTablePropsContextProvider } from '../../Reservations/ReservationsIndexTable/contexts/TablePropsContext'
import { StockContextProvider } from '../../Stock/StockShow/contexts/StockContext'
import { UnitsOfMeasureContextProvider } from '../ServicesShow/contexts/UnitsOfMeasureContext'

const LazyRequirementsIndexKanban = lazy(async () => import('../../Requirements/pages/RequirementsIndexKanban'))

const LazyRequirementsIndexTable = lazy(async () => import('../../Requirements/pages/RequirementsIndexTable'))

const LazyReservationsIndexTable = lazy(async () => import('../../Reservations/pages/ReservationsIndexTable'))

const LazyRequirementsShow = lazy(async () => import('../../Requirements/pages/RequirementsShow'))
const LazyReservationsShow = lazy(async () => import('../../Reservations/pages/ReservationsShow'))

const LazyReservationsIndexKanban = lazy(async () => import('../../Reservations/pages/ReservationsIndexKanban'))

const LazyDispatchGuidesIndex = lazy(async () => import('../../DispatchGuides/pages/DispatchGuidesIndex'))
const LazyReceptionsIndex = lazy(async () => import('../../Receptions/pages/ReceptionsIndex'))
const LazyReceptionShow = lazy(async () => import('../../Receptions/pages/ReceptionShow'))

const LazyStocksIndexTable = lazy(async () => import('../../Stock/pages/StockIndexTable'))

const LazyMaterialsIndex = lazy(async () => import('../../Materials/pages/MaterialsIndex'))
const LazyMaterialShow = lazy(async () => import('../../Materials/pages/MaterialShow'))

const LazyOrganizationSettings = lazy(async () => import('../../Organizations/pages/OrganizationSettings'))

const ServicesShow = () => {
  return (
    <ServiceContextProvider>
      <UnitsOfMeasureContextProvider>
        <ConfirmationModalProvider>
          <ServiceInit>
            <Routes>
              <Route element={<ServiceLayout />}>
                <Route
                  path="/requirements"
                  element={
                    <SuspensedView>
                      <RequirementContextProvider>
                        <LazyRequirementsIndexKanban />
                      </RequirementContextProvider>
                    </SuspensedView>
                  }
                />
                <Route
                  path="/requirements-table"
                  element={
                    <SuspensedView>
                      <TablePropsContextProvider>
                        <RequirementContextProvider>
                          <LazyRequirementsIndexTable />
                        </RequirementContextProvider>
                      </TablePropsContextProvider>
                    </SuspensedView>
                  }
                />

                <Route
                  path="/reservations"
                  element={
                    <SuspensedView>
                      <ReservationContextProvider>
                        <LazyReservationsIndexKanban />
                      </ReservationContextProvider>
                    </SuspensedView>
                  }
                />

                <Route
                  path="/reservations-table"
                  element={
                    <SuspensedView>
                      <ReservationTablePropsContextProvider>
                        <ReservationContextProvider>
                          <LazyReservationsIndexTable />
                        </ReservationContextProvider>
                      </ReservationTablePropsContextProvider>
                    </SuspensedView>
                  }
                />

                <Route
                  path="/stock"
                  element={
                    <SuspensedView>
                      <ReservationContextProvider>
                        <StockContextProvider>
                          <LastReservationContextProvider>
                            <StockInit>
                              <FilterContextProvider>
                                <LazyStocksIndexTable />
                              </FilterContextProvider>
                            </StockInit>
                          </LastReservationContextProvider>
                        </StockContextProvider>
                      </ReservationContextProvider>
                    </SuspensedView>
                  }
                />

                <Route
                  path="/dispatch_guides"
                  element={
                    <SuspensedView>
                      <LazyDispatchGuidesIndex />
                    </SuspensedView>
                  }
                />

                <Route
                  path="/enterprise_settings"
                  element={
                    <SuspensedView>
                      <LazyOrganizationSettings />
                    </SuspensedView>
                  }
                />

                <Route
                  path="/receptions/*"
                  element={
                    <SuspensedView>
                      <Routes>
                        <Route path="" element={<LazyReceptionsIndex />} />
                        <Route
                          path=":dispatchGuideId"
                          element={
                            <DispatchGuideContextProvider>
                              <LazyReceptionShow />
                            </DispatchGuideContextProvider>
                          }
                        />
                      </Routes>
                    </SuspensedView>
                  }
                />

                <Route
                  path="/materials/*"
                  element={
                    <SuspensedView>
                      <Routes>
                        <Route path="" element={<LazyMaterialsIndex />} />
                        <Route
                          path=":materialId"
                          element={
                            <MaterialContextProvider>
                              <LazyMaterialShow />
                            </MaterialContextProvider>
                          }
                        />
                      </Routes>
                    </SuspensedView>
                  }
                />
              </Route>

              <Route
                path="/requirements/:requirementId"
                element={
                  <SuspensedView>
                    <RequirementContextProvider>
                      <LazyRequirementsShow />
                    </RequirementContextProvider>
                  </SuspensedView>
                }
              />

              <Route
                path="/reservations/:reservationId"
                element={
                  <SuspensedView>
                    <ReservationContextProvider>
                      <LastReservationContextProvider>
                        <StockInit>
                          <FilterContextProvider>
                            <LazyReservationsShow />
                          </FilterContextProvider>
                        </StockInit>
                      </LastReservationContextProvider>
                    </ReservationContextProvider>
                  </SuspensedView>
                }
              />
            </Routes>
          </ServiceInit>
        </ConfirmationModalProvider>
      </UnitsOfMeasureContextProvider>
    </ServiceContextProvider>
  )
}

export default ServicesShow
