import { useEffect, useMemo } from "react"
import Select from "react-select"
import { formatOptionSelect } from "../../../../helpers/selectHelper"
import { useListTags } from "../../hooks/useService"
import { useServiceContext } from "../../hooks/useServiceContext"

type RoleSelectProps = {
  identifier: string
  value: any
  setValue: React.Dispatch<React.SetStateAction<any>>
}
export const RoleSelect = ({ identifier, value, setValue }: RoleSelectProps) => {
  const { service } = useServiceContext()
  const { data: tags, isPending: isPendingTags } = useListTags(service?.id)

  const defaultValue = useMemo(() => tags?.find((tag: any) => tag.identifier === identifier), [tags])

  const newTags = useMemo(() => tags?.map((tag: any) => formatOptionSelect(tag)), [tags])

  const handleChange = (selectedValue: any): void => {
    if (selectedValue) {
      setValue(selectedValue)
    }
  }

  const customStyles = {
    option: (styles: any) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles: any) => ({
      ...styles,
      cursor: "pointer",
    }),
    valueContainer: (provided: any) => {
      return { ...provided, padding: "0 12px" }
    },
  }
  useEffect(() => {
    if (defaultValue) {
      setValue(formatOptionSelect(defaultValue))
    }
  }, [defaultValue])

  if (isPendingTags) return <></>

  return (
    <Select
      isClearable={false}
      defaultValue={formatOptionSelect(defaultValue) ?? null}
      value={value}
      onChange={handleChange}
      placeholder="Selecciona tu cargo"
      noOptionsMessage={() => null}
      options={newTags}
      styles={customStyles}
    />
  )
}
