import { useState } from "react"
import { UseFormRegister } from "react-hook-form"

const PasswordInput = ({
  disabled,
  register,
  registerName,
  placeholder,
  isSolid,
  rules,
  showPasswordStrong,
  bypassPattern,
}: {
  disabled: boolean
  register: UseFormRegister<any>
  registerName: string
  placeholder: string
  isSolid: boolean
  rules: any
  showPasswordStrong?: boolean
  bypassPattern?: boolean
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const validationRules = bypassPattern
    ? { ...rules }
    : {
        ...rules,
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          message:
            "La contraseña debe tener al menos 8 caracteres, un dígito, un símbolo, una letra mayúscula y una minúscula.",
        },
      }

  const evaluatePasswordCriteria = (password: string) => {
    const criteria = {
      length: password.length >= 8,
      digit: /\d/.test(password),
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      symbol: /\W/.test(password),
    }

    return criteria
  }

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    digit: false,
    lowerCase: false,
    upperCase: false,
    symbol: false,
  })

  const { ref, onChange: formOnChange, ...rest } = register(registerName, validationRules)

  const handleChange = (e: any) => {
    const criteria = showPasswordStrong
      ? evaluatePasswordCriteria(e.target.value)
      : {
          length: false,
          digit: false,
          lowerCase: false,
          upperCase: false,
          symbol: false,
        }
    setPasswordCriteria(criteria)

    formOnChange(e)
  }

  return (
    <>
      <div className="form_group">
        <input
          disabled={disabled}
          id={registerName}
          autoComplete="off"
          className={"form-control form-control-lg " + (isSolid ? "form-control-solid" : "")}
          placeholder={placeholder}
          type={isVisible ? "text" : "password"}
          ref={ref} // Usar ref desde register
          onChange={handleChange}
          {...rest}
        ></input>
        <div className="img" onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? <i className="bi bi-eye-slash fs-3" /> : <i className="bi bi-eye fs-3" />}
        </div>
      </div>
      {showPasswordStrong && (
        <div className="mt-1 d-flex flex-wrap justify-content-end gap-2 text-muted me-1 fs-8">
          <div>
            <i
              className={
                passwordCriteria.length
                  ? "text-success bi bi-check-circle-fill fs-8"
                  : "text-muted bi bi-x-circle-fill fs-8"
              }
            ></i>{" "}
            8 caracteres
          </div>
          <div>
            <i
              className={
                passwordCriteria.digit
                  ? "text-success bi bi-check-circle-fill fs-8"
                  : "text-muted bi bi-x-circle-fill fs-8"
              }
            ></i>{" "}
            Número
          </div>
          <div>
            <i
              className={
                passwordCriteria.lowerCase
                  ? "text-success bi bi-check-circle-fill fs-8"
                  : "text-muted bi bi-x-circle-fill fs-8"
              }
            ></i>{" "}
            Minúscula
          </div>
          <div>
            <i
              className={
                passwordCriteria.upperCase
                  ? "text-success bi bi-check-circle-fill fs-8"
                  : "text-muted bi bi-x-circle-fill fs-8"
              }
            ></i>{" "}
            Mayúscula
          </div>
          <div>
            <i
              className={
                passwordCriteria.symbol
                  ? "text-success bi bi-check-circle-fill fs-8"
                  : "text-muted bi bi-x-circle-fill fs-8"
              }
            ></i>{" "}
            Símbolo
          </div>
        </div>
      )}
    </>
  )
}

export default PasswordInput
