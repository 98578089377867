import { ListParticipantLoader } from '../../../../components/loaders/ListParticipantLoader'
import ListUsers from '../../../../components/users/ListUsers'
import { User } from '../../../../features/invitations/invitation.types'

type ServiceTeamParticipantsProps = {
  users: User[]
  isPending: boolean
  canRegisterNewInvitation: boolean | undefined
  handleClick: () => void
  hideTag?: boolean
  onDelete: (userId: number) => void
}

export const ServiceTeamParticipants = ({
  users,
  isPending,
  canRegisterNewInvitation,
  handleClick,
  hideTag,
  onDelete,
}: ServiceTeamParticipantsProps) => {
  if (isPending) return <ListParticipantLoader />
  return (
    <>
      <div className="d-flex flex-row-reverse w-100 my-5">
        {canRegisterNewInvitation && (
          <button className="btn btn-primary btn-sm end-0 px-3 py-2" style={{ top: '-8px' }}>
            <span
              className="cursor-pointer d-flex align-items-center"
              title="Añadir participante"
              onClick={handleClick}
            >
              <i className="text-white bi bi-person-add fs-6 my-2 mx-2" />
              <span className="d-sm-block d-none">Invitar Usuario</span>
            </span>
          </button>
        )}
      </div>
      <ListUsers
        users={users}
        hideTag={hideTag}
        onDelete={onDelete}
        canDelete={canRegisterNewInvitation}
      />
    </>
  )
}
