import { useEffect, useState } from "react"
import { FilesProps } from "../../activity.types"
import { useCreateAttachment } from "../../hook/useActivity"
import ProgressBar from "react-bootstrap/ProgressBar"
import { AttachmentPreview } from "../attachment/AttachmentPreview"

type EntryItemAttachmentProps = {
  file: FilesProps
  handleDelete: any
  entryUuid: string
  resourceType: string
  resourceId: string
}

export const PendingAttachment = ({
  file,
  entryUuid,
  handleDelete,
  resourceId,
  resourceType,
}: EntryItemAttachmentProps) => {
  const [isPendingImage, setIsPendingImage] = useState<boolean>(true)
  const [progress, setProgress] = useState<number>(0)
  const { mutate: createAttachment, isPending: creatingAttachment, isError } = useCreateAttachment()

  useEffect(() => {
    const uploadFile = (file: any) => {
      setIsPendingImage(true)
      const formData = new FormData()
      formData.append("file", file.file)
      formData.append("attachment_id", file.id)
      formData.append("resource_type", resourceType)
      formData.append("resource_id", resourceId)

      const config = {
        onUploadProgress: ({ total, loaded }: any) => {
          setProgress(Number(((loaded / total) * 100).toFixed(2)))
        },
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      }

      if (creatingAttachment) return
      createAttachment(
        { id: entryUuid, formData, config },
        {
          onSuccess: () => {
            setIsPendingImage(false)
          },
          onError: () => {
            setIsPendingImage(false)
          },
        },
      )
    }

    uploadFile(file)
  }, [file.id])

  if (isPendingImage) {
    return (
      <div className="d-flex flex-column my-2 ms-3">
        <div className="w-150px h-100px  border-light d-flex flex-column justify-content-between align-items-center position-relative">
          <div className="spinner-border spinner-border-sm position-absolute activity__imageLoader"></div>

          <span className="fs-8 activity__attachmentText  position-absolute bottom-0 end-0 bg-white py-1 px-2 w-100 text-muted">
            {file.file.name}
          </span>
        </div>

        <ProgressBar now={progress} className="activity__progress" variant="success" />
      </div>
    )
  }

  if (isError) return <></>

  return (
    <div className="w-150px h-120px ms-3 my-4 position-relative d-flex align-items-center justify-content-center border-light">
      <AttachmentPreview extension={file.file.name} version={URL.createObjectURL(file.file)} />
      <i
        className="bi bi-x fs-1 m-1 position-absolute top-0 end-0 activity__attachmentIcon--delete"
        onClick={() => handleDelete(file)}
      ></i>
      <span className="fs-8 activity__attachmentText position-absolute bottom-0 end-0 bg-white py-1 px-2 w-100 text-muted">
        {file.file.name}
      </span>
    </div>
  )
}
