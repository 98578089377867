import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useForgotPassword } from './hooks/useForgotPassword'

interface ForgotInputs {
  email: string
}

export const ForgotPassword = (): React.ReactElement => {
  const { loading, sendResetInstructions } = useForgotPassword()

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setFocus,
  } = useForm<ForgotInputs>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })

  useEffect(() => {
    document.title = `Mina365 | ¿Olvidaste tu contraseña?`
    setFocus('email')
  }, [])

  const handleLogin = ({ email }: ForgotInputs): void => {
    void sendResetInstructions(email)
  }

  return (
    <form className="form w-100" onSubmit={handleSubmit(handleLogin)}>
      <div className="mb-7">
        <h2 className="mb-3 text-center">¿Olvidaste tu contraseña?</h2>
        <div className="text-gray-700 fs-6 text-center">
          Introduce tu email y te enviaremos un link de recuperación
        </div>
      </div>

      <div className="mb-10">
        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="email">
          Email
        </label>
        <input
          placeholder="example@mina365.com"
          disabled={loading}
          className={'form-control form-control-lg form-control-solid'}
          type="email"
          autoComplete="off"
          {...register('email', { required: true })}
        />
      </div>

      <div className="text-center">
        <button
          type="submit"
          className="btn btn-lg btn-primary w-100"
          disabled={loading || !isValid}
        >
          {loading ? (
            <div className="spinner-border spinner-border-sm"></div>
          ) : (
            <span className="indicator-label">Enviar</span>
          )}
        </button>
      </div>
    </form>
  )
}
