import React from 'react'
import { AsideMenuItem } from './AsideMenuItem'

export const AsideMenuMain = (): React.ReactElement => {
  return (
    <>
      <AsideMenuItem to="/services" title="Servicios" fontIcon="bi-geo-alt fs-4" className="py-2" />
    </>
  )
}
