import { NavLink, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useServiceContext } from '../hooks/useServiceContext'
import { RESERVATION_MODULES } from '../../../Stock/constants'

const navLabel = {
  stock_imports: 'Importar Stock',
  requirements: 'Requerimientos',
  stock: 'Stock',
  transfers: 'Transferencias',
  relocations: 'Reubicaciones',
  provisions: 'Reservas',
  returns: 'Devoluciones',
  dispatches: 'Despacho',
  dispatch_guides: 'Registro de guías',
  receptions: 'Recepción de materiales',
  materials: 'Catálogo',
  enterprise_settings: 'Perfil corporativo'
}

const navIcon = {
  requirements: 'bi-cart',
  stock_imports: 'bi-cloud-upload',
  stock: 'bi-layers',
  transfers: 'bi-arrow-left-right',
  provisions: 'bi-truck-flatbed',
  returns: 'bi-truck-flatbed',
  dispatches: 'bi-truck-flatbed',
  dispatch_guides: 'bi-file-earmark-arrow-up',
  receptions: 'bi-file-earmark-arrow-down',
  materials: 'bi-list-ul',
  enterprise_settings: 'bi-buildings'
}

export const ServiceLayout = () => {
  const { service } = useServiceContext()
  const location = useLocation()

  const modulesWithoutTab = ['stock_creations', 'stock_adjustments']
  const modules = service?.modules.filter((module) => !modulesWithoutTab.includes(module)) || []

  const [searchParams] = useSearchParams()
  const processType = searchParams.get('process_type')

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body p-0">
          <div className="mb-5">
            <nav className="overflow-auto">
              <ul className="nav nav-tabs flex-nowrap text-nowrap">
                {modules.map((module) => {
                  const isReservationModule = RESERVATION_MODULES.includes(module)

                  let navLink = module

                  if (isReservationModule) {
                    let processType = module
                    navLink = `reservations?process_type=${processType}`
                  }

                  return (
                    <li className="nav-item" key={module}>
                      <NavLink
                        className={(navData) => {
                          let isActive = navData.isActive

                          if (module === 'requirements') {
                            isActive = isActive || location.pathname.includes('requirements-table')
                          }

                          if (isReservationModule) {
                            isActive =
                              processType == module && (isActive || location.pathname.includes(`reservations-table`))
                          }

                          return isActive
                            ? 'nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 active'
                            : 'nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0'
                        }}
                        to={navLink}
                      >
                        <span className="d-flex align-items-center gap-2">
                          <i className={`bi ${(navIcon as any)[module]} mr-2 fs-3`} />{' '}
                          <span>{(navLabel as any)[module]}</span>
                        </span>
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
