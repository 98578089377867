import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import MarketplaceLayout from '../../layouts/MarketplaceLayout'
import SuspensedView from '../../components/SuspensedView'
import { Page404 } from '../../pages/Page404'
import { BreadcrumbContextProvider } from '../../components/breadcrumb/contexts/BreadcrumbContext'
import { PartnerContextProvider } from '../../Marketplace/Partners/contexts/PartnerContext'
import { UnitsOfMeasureContextProvider } from '../../Marketplace/Materials/context/UnitsOfMeasureContext'

const LazyMaterialsPage = lazy(async () => import('../../pages/Marketplace/MaterialsPage'))
const LazyPartnersPage = lazy(async () => import('../../pages/Marketplace/PartnersPage'))

export const MarketplaceRoutes = (): React.ReactElement => {
  return (
    <BreadcrumbContextProvider>
      <UnitsOfMeasureContextProvider>
        <Routes>
          <Route element={<MarketplaceLayout />}>
            <Route
              path="/*"
              element={
                <SuspensedView>
                  <LazyMaterialsPage />
                </SuspensedView>
              }
            />
            <Route
              path=":materialSlug/*"
              element={
                <SuspensedView>
                  <LazyMaterialsPage />
                </SuspensedView>
              }
            />
            <Route
              path="empresas/*"
              element={
                <PartnerContextProvider>
                  <SuspensedView>
                    <LazyPartnersPage />
                  </SuspensedView>
                </PartnerContextProvider>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </UnitsOfMeasureContextProvider>
    </BreadcrumbContextProvider>
  )
}
