import React, { useEffect, useState } from 'react'
import { useGetProfile, useUpdateProfile } from '../../hooks/useService'
import { toast } from 'react-hot-toast'
import { Modal } from 'react-bootstrap'
import { Profile } from '../../../services.types'
import { RoleSelect } from './RoleSelect'
import { RoleShow } from './RoleShow'

type RoleFormProps = {
  profile: Profile
  serviceId: string
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RoleForm = ({ profile, setIsOpen, serviceId }: RoleFormProps) => {
  const { mutate: updateProfile, isPending: updatingProfile } = useUpdateProfile(serviceId)
  const [value, setValue] = useState<any>(null)

  const handleSubmit = () => {
    if (updatingProfile) return

    updateProfile(
      { serviceId: serviceId, profile: { tag_identifier: value.value } },
      {
        onError: () => {
          toast.success('Error al actualizar cargo')
        }
      }
    )
    setIsOpen(false)
  }

  return (
    <>
      <div className="mb-2">
        <RoleSelect identifier={profile.tag?.identifier} value={value} setValue={setValue} />
      </div>
      <div className="pt-2 d-flex flex-row-reverse">
        <button className="btn btn-sm btn-success" onClick={handleSubmit} disabled={!value}>
          Actualizar
        </button>
      </div>
    </>
  )
}

type ServiceMenuRoleProps = {
  serviceId: string | undefined
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ServiceMenuRole = ({ serviceId, isOpen, setIsOpen }: ServiceMenuRoleProps) => {
  const { data: profile, isPending: isPendingProfile } = useGetProfile(serviceId)

  const onClose = () => {
    if (!profile.tag?.name) return
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isPendingProfile && !profile.tag?.name) {
      return setIsOpen(true)
    }
  }, [isPendingProfile])

  return (
    <div className="ms-6 d-flex align-items-center">
      <div className="mx-2">
        <span className="text-secondary d-block fs-7">Mi cargo</span>
        <span className="text-success">
          {isPendingProfile ? (
            <div className="service__loader"></div>
          ) : (
            <RoleShow role={profile.tag ? profile.tag.name : '  -----'} />
          )}
        </span>
      </div>
      <i className="bi bi-caret-down-fill service__ocupationIcon"></i>
      {isOpen && (
        <Modal show={isOpen} size="sm" className="service__ocupationModal">
          <Modal.Header>
            <span className="fw-bold fs-3">Mi cargo</span>
            <i className="bi bi-x-lg fs-1 cursor-pointer " onClick={onClose} />
          </Modal.Header>
          <Modal.Body className="pt-0">
            <RoleForm serviceId={String(serviceId)} setIsOpen={setIsOpen} profile={profile} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
