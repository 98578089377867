import { createContext, useState } from 'react'
import { Stock, StockContextProviderProps, StockContextType } from '../../stock.types'

export const StockContext = createContext<StockContextType>({
  stock: null,
})
export const StockContextProvider = ({ children }: StockContextProviderProps) => {
  const [stock, setStock] = useState<Stock | null>(null)
  return <StockContext.Provider value={{ stock, setStock }}>{children}</StockContext.Provider>
}
