import moment from 'moment'
import { Entry } from '../../activity.types'

export const SignaturesInvalidation = ({
  entry,
  canHaveTimeline,
}: {
  entry: Entry
  canHaveTimeline: boolean
}) => {
  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-danger">
        <i className="fs-3 bi text-white activity__icon bi-exclamation-lg"></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <div className="activity__text">
            <span className="me-2 d-flex flex-wrap">
              <span className="activity__span fw-bold">{entry.user.name}</span>
              {entry.config.signature_users.length > 1
                ? `ha invalidado las firmas de `
                : 'ha invalidado la firma de'}
              <SignaturesUsers signatures={entry.config.signature_users} />

              <span className="activity__date text-secondary fs-7 d-inline-block">
                {moment(entry.created_at).format('DD MMM h:mm A')}
              </span>
            </span>
          </div>
        </div>
        <div className="activity__body me-3 pb-2 border-bottom border-1"></div>
      </div>
    </div>
  )
}

const SignaturesUsers = ({ signatures }: { signatures: string[] }) => {
  const len = signatures.length

  switch (len) {
    case 1: {
      return <span className="fst-italic me-1">{signatures[0]}</span>
      break
    }
    case 2: {
      return (
        <span className="fst-italic me-1">
          {signatures[0]} y {signatures[1]}
        </span>
      )
      break
    }
    default: {
      return (
        <span className="fst-italic me-1">
          {signatures.slice(0, len - 1).join(', ')} y {signatures[len - 1]}
        </span>
      )
      break
    }
  }
}
