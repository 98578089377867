import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthPage } from '../../pages/AuthPage'
import { PrivateRoutes } from './PrivateRoutes'
import { MarketplaceRoutes } from './MarketplaceRoutes'

export const AppRoutes = (): React.ReactElement => {
  const { token } = useContext(AuthContext)

  return (
    <Router>
      <Routes>
        <Route path="mercado-minero/*" element={<MarketplaceRoutes />} />

        {token != null ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/services" />} />
          </>
        ) : (
          <>
            <Route path="auth/*" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Routes>
    </Router>
  )
}
