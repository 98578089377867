import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'

const getLastReservation = (serviceId: string | undefined) => {
  return axiosPrivate
    .get(`/stocks/boards/${serviceId}/reservation`)
    .then((response) => response.data.reservation)
}

export const useGetLastReservation = (serviceId: string | undefined) => {
  return useQuery({
    queryKey: ['lastReservation', serviceId],
    queryFn: () => getLastReservation(serviceId),
    retry: 0,
  })
}

const createReservation = ({serviceId, processType}: {serviceId: string | undefined, processType: string}) => {
  return axiosPrivate.post(`/stocks/boards/${serviceId}/reservation`, {process_type: processType})
}

export const useCreateReservation = (serviceId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createReservation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lastReservation', serviceId]
      })
    },
  })
}

const createItem = ({
  reservationId,
  stockId,
}: {
  reservationId: string | undefined
  stockId: string | undefined
}) => {
  return axiosPrivate.post(`/reservations/reservations/${reservationId}/items`, {
    stock_id: stockId,
  })
}

export const useCreateItem = (serviceId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createItem,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lastReservation', serviceId]
      })
    },
  })
}
