import React from 'react'
import { Suspense } from 'react'
import Config from '../../setup/config.json'
import TopBarProgress from 'react-topbar-progress-indicator'

interface SuspensedViewProps {
  children: React.ReactNode
}

const SuspensedView = ({ children }: SuspensedViewProps): React.ReactElement => {
  const baseColor = Config.colors.primaryColor
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}


export default SuspensedView
