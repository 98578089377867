import React, { createContext, useState } from 'react'
import { Account, AccountContextProviderProps, AccountContextType } from '../account.types'

export const AccountContext = createContext<AccountContextType>({
  account: null,
})

export const AccountContextProvider = ({
  children,
}: AccountContextProviderProps): React.ReactElement => {
  const [account, setAccount] = useState<Account | null>(null)

  return (
    <AccountContext.Provider value={{ account, setAccount }}>{children}</AccountContext.Provider>
  )
}
