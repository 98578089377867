import React from 'react'
import { AsideMenuItem } from './AsideMenuItem'

export const AsideMenuMain = (): React.ReactElement => {
  return (
    <>
      <AsideMenuItem to="" title="Mercado" fontIcon="bi-minecart-loaded fs-4" className="py-2" />
      <AsideMenuItem to="empresas" title="Empresas" fontIcon="bi-buildings fs-4" className="py-2" />
    </>
  )
}
