import { MobileLogo } from './MobileLogo'
import { MobileToggle } from './MobileToggle'
import { PageTitle } from './PageTitle'
import { Topbar } from './Topbar'

export const HeaderWrapper = (): React.ReactElement => {
  return (
    <div className="header align-items-stretch">
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        <MobileToggle />
        <MobileLogo />
        <div className="d-flex align-items-center">
          <PageTitle />
        </div>
        <Topbar />
      </div>
    </div>
  )
}
