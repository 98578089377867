import axios from 'axios'

const BASE_URL = import.meta.env.VITE_REACT_APP_BACKEND_SERVER

const authAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-type': 'application/json'
  }
})

const axiosPrivate = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  headers: {
    'content-type': 'application/json'
  },
  withCredentials: true
})

const axiosMarketplace = axios.create({
  baseURL: `${BASE_URL}/marketplace`,
  headers: {
    'content-type': 'application/json'
  }
})

axiosPrivate.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      window.location.reload()
    } else {
      return await Promise.reject(error)
    }
  }
)

export { axiosPrivate, axiosMarketplace }
export default authAxios
