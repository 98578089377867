import ServicesShowLoader from '../../../components/loaders/ServicesShowLoader'
import { useServiceInit } from '../hooks/useServiceInit'

import { createPortal } from 'react-dom'
import { ServiceMobileMenu } from '../components/ServiceMenu/ServiceMobileMenu'
import { ServiceMenu } from '../components/ServiceMenu/ServiceMenu'
import { useUnitsOfMeasureInit } from '../hooks/useUnitsOfMeasureInit'

interface ServiceInitProps {
  children: React.ReactNode
}

export const ServiceInit = ({ children }: ServiceInitProps): React.ReactElement => {
  const { isLoading: isServiceLoading } = useServiceInit()
  const { isLoading: isUnitOfMeasuresLoading } = useUnitsOfMeasureInit()

  const isLoading = isServiceLoading || isUnitOfMeasuresLoading

  return isLoading ? (
    <ServicesShowLoader />
  ) : (
    <div className="position-relative">
      <ServiceMobileMenu />
      {children}
      {createPortal(<ServiceMenu />, document.querySelector('#page-title-portal')!)}
    </div>
  )
}
