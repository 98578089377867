import { useEffect } from 'react'
import { useBreadcrumbContext } from '../../components/breadcrumb/hooks/useBreadcrumbContext'
import CardService from '../ServiceIndex/CardService'
import { ServiceBD } from '../services.types'
import ServiceIndexLoader from '../loaders/ServicesIndexLoader'

import NoData from '../../assets/images/undraw/no-data.svg'
import { useListServices } from '../ServicesShow/hooks/useService'

const ServicesIndex = () => {
  const { setBreadcrumb } = useBreadcrumbContext()
  const { data: services, isPending } = useListServices()

  useEffect(() => {
    if (setBreadcrumb != null) {
      setBreadcrumb({
        title: 'Servicios',
        links: [],
      })
    }
  }, [])

  if (isPending) {
    return (
      <div className="container-xxl">
        <div className="row g-5 g-xl-10">
          <div className="col-sm-12 col-md-6">
            <ServiceIndexLoader />
          </div>
          <div className="col-sm-12 col-md-6">
            <ServiceIndexLoader />
          </div>
        </div>
      </div>
    )
  }

  if (services.length === 0) {
    return (
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div
                className="card-body d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <div>
                  <img alt="404" src={NoData} className="h-200px" />

                  <span className="fs-4 text-muted mt-10 d-block text-center">
                    Sin servicios asignados
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-xxl">
      <div className="row g-5 g-xl-10">
        {services.map((service: ServiceBD) => (
          <CardService service={service} key={service.id} />
        ))}
      </div>
    </div>
  )
}

export default ServicesIndex
