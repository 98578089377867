import { Outlet } from 'react-router-dom'
import { HeaderWrapper } from './MarketplacePartials/header/HeaderWrapper'
import { AsideDefault } from './MarketplacePartials/sidebar/AsideDefault'
import { MenuContextProvider } from '../components/menu/MenuContext'
import { Breadcrumb } from '../components/breadcrumb/Breadcrumb'
import { useUnitsOfMeasureInit } from '../Marketplace/Materials/hooks/useUnitsOfMeasureInit'

const MarketplaceLayout = (): React.ReactElement => {
  const { isLoading: isUnitOfMeasuresLoading } = useUnitsOfMeasureInit()

  return (
    <MenuContextProvider>
      <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed">
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div className="wrapper d-flex flex-column flex-row-fluid">
            <HeaderWrapper />
            <div className="content d-flex flex-column flex-column-fluid">
              <div className="content-breadcrumbs mb-4 px-5">
                <Breadcrumb />
              </div>
              {!isUnitOfMeasuresLoading && <Outlet />}
            </div>
          </div>
        </div>
      </div>
    </MenuContextProvider>
  )
}

export default MarketplaceLayout
