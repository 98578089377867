import { Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'

export const PageTitle = (): React.ReactElement => {
  return (
    <>
      <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0">
        <Breadcrumb />
      </div>

      <div id="page-title-portal" className="d-none d-lg-block"></div>
    </>
  )
}
