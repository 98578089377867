import { NavLink } from 'react-router-dom'
import { useMenuContext } from '../../../components/menu/useMenuContext'

interface Props {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  className: string
  hasBullet?: boolean
  outside?: boolean
}

export const AsideMenuItem = ({
  to,
  className,
  title,
  fontIcon,
  hasBullet,
}: Props): React.ReactElement => {
  const { setVisible } = useMenuContext()
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        'menu-item ' + (isActive ? `here show ${className}` : className)
      }
      onClick={() => setVisible(false)}
    >
      <div className="menu-link menu-center">
        {Boolean(hasBullet) && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {fontIcon != null ? (
          <>
            <span className="menu-icon me-0">
              <i className={`bi ${fontIcon}`}></i>
            </span>
            <span className="menu-title">{title}</span>
          </>
        ) : (
          <span className="menu-title">{title}</span>
        )}
      </div>
    </NavLink>
  )
}
