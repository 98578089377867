export const formatQuantity = (number: string | number | undefined): string => {
  if (number === undefined || number === null) return ''

  const num = typeof number === 'string' ? parseFloat(number) : number

  if (isNaN(num)) return ''

  // Verifica si el número es efectivamente un entero
  if (Number.isInteger(num)) {
    return num.toString()
  }

  // Si el número no es un entero, formatearlo con hasta 4 decimales y eliminar ceros innecesarios
  return num.toFixed(4).replace(/\.?0+$/, '')
}
