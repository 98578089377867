import { AuthInit } from "./features/authentication/AuthInit"
import { AppRoutes } from "./setup/routes/AppRoutes"
import { Toaster } from "react-hot-toast"

import moment from "moment"
import "moment/dist/locale/es"
moment.locale("es")

function App(): React.ReactElement {
  return (
    <AuthInit>
      <Toaster />
      <AppRoutes />
    </AuthInit>
  )
}

export default App
