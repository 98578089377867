import React from 'react'
import { Link } from 'react-router-dom'
import DashboardMobileLogo from '../../../assets/images/dashboard_mobile_logo.svg'
import StagingMobileLogo from '../../../assets/images/staging_mobile_logo.svg'

export const MobileLogo = (): React.ReactElement => {
  const logo = import.meta.env.VITE_REACT_APP_STAGING ? StagingMobileLogo : DashboardMobileLogo

  return (
    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
      <Link to="/services" className="d-lg-none">
        <img alt="Logo" src={logo} className="h-40px" />
      </Link>
    </div>
  )
}
