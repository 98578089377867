import { useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import AsyncCreatableSelect from "react-select/async-creatable"
import Select from "react-select"
import {
  DropdownIndicator,
  FormatOptionLabel,
  IndicatorSeparator,
  isOptionDisabled,
} from "./components/SearchSelectComponents"
import { Member, NewUserType, Option } from "./invitation.types"
import { roleLabel } from "./locale"
import { axiosPrivate } from "../../setup/axios"

interface InvitationFormProps {
  onSubmit: any
  isPending: boolean
  resourceType: string
  resourceId: string
  allowedRoles: string[] | undefined
  title: string
}

export const InvitationFormAsync = ({
  allowedRoles,
  onSubmit,
  isPending,
  resourceType,
  resourceId,
  title,
}: InvitationFormProps) => {
  const [invitableSelected, setInvitableSelected] = useState<boolean>(false)
  const [showRoleSelect, setShowRoleSelect] = useState<boolean>(false)
  const [typingTimeOut, setTypingTimeOut] = useState<any>()

  const { setValue, watch, handleSubmit, control } = useForm<NewUserType>({
    mode: "onChange",
    defaultValues: {
      role: "",
      email: "",
    },
  })
  const selectInvitable = (selectedValue: any): void => {
    if (selectedValue) {
      setShowRoleSelect(true)
      if (Boolean(selectedValue.value) && Boolean(selectedValue.value.includes("@"))) {
        setValue("email", selectedValue.value)
      } else if (selectedValue.email) {
        setValue("email", selectedValue.email)
      }
      setInvitableSelected(true)
    } else {
      setValue("role", "")
      setShowRoleSelect(false)
      setInvitableSelected(false)
    }

    if (allowedRoles && allowedRoles.length <= 1) {
      setValue("role", allowedRoles[0])
      setShowRoleSelect(false)
    }
  }

  const roleOptions = useMemo((): Option[] | undefined => {
    return (allowedRoles || []).map((role) => {
      return { value: role, label: roleLabel(role) }
    })
  }, [allowedRoles])

  const handleRoleSelect = (selectedValue: any): void => {
    setValue("role", selectedValue.value)
  }

  const loadOptionsCreatable = (inputValue: string, callback: (options: Member[]) => void): void => {
    clearTimeout(typingTimeOut)
    const timeout = setTimeout(() => {
      void axiosPrivate
        .get(`/member_suggestions?resource_type=${resourceType}&resource_id=${resourceId}&q=${inputValue}`)
        .then((response) => {
          const { member_suggestions: data } = response.data
          callback(data)
        })
    }, 400)
    setTypingTimeOut(timeout)
  }

  const formIsCompleted = invitableSelected && watch("role")

  return (
    <div className="pt-0 mb-5 w-100">
      <div className="text-center fs-2 fw-bold mb-5">{title}</div>
      <div className="px-0 px-lg-15">
        <AsyncCreatableSelect
          isClearable
          blurInputOnSelect
          components={{ DropdownIndicator, IndicatorSeparator }}
          loadOptions={loadOptionsCreatable}
          placeholder="Busca por nombres o ingresa un email"
          formatOptionLabel={FormatOptionLabel}
          onChange={selectInvitable}
          isOptionDisabled={isOptionDisabled}
          noOptionsMessage={() => null}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="form mt-5">
          {showRoleSelect && (
            <div className="d-flex flex-column mb-5">
              <Controller
                name="role"
                control={control}
                rules={{ required: true }}
                render={() => (
                  <Select
                    options={roleOptions}
                    onChange={handleRoleSelect}
                    placeholder="Selecciona el permiso del colaborador"
                  />
                )}
              />
            </div>
          )}

          <button
            className={formIsCompleted ? "btn btn-primary w-100" : "btn btn-primary w-100 disabled"}
            disabled={isPending}
          >
            {isPending ? (
              <div className="spinner-border spinner-border-sm mx-4"></div>
            ) : (
              <>
                {invitableSelected && (
                  <span className="svg-icon svg-icon-2">
                    <i className="bi bi-send-fill"></i>
                  </span>
                )}

                {invitableSelected ? "Enviar invitación" : "Selecciona un colaborador en el buscador"}
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  )
}
