import { Link } from 'react-router-dom'
import { CardServiceProps } from '../services.types'
import '../services.css'
import Logo from './Logo'

const CardService = ({ service }: CardServiceProps) => {
  let serviceLink = `/services/${service.id}/${service.modules[0]}`
  if (['dispatches', 'provisions', 'returns'].includes(service.modules[0])) {
    serviceLink = `/services/${service.id}/reservations?process_type=${service.modules[0]}`
  }

  return (
    <div className="col-sm-12 col-md-6">
      <div className="card card-flush h-xl-100 mb-5 mb-xl-10">
        <div className="card-body py-9">
          <div className="row gx-9 h-100">
            <div className="col-sm-5 mb-10 mb-sm-0 d-flex align-items-center">
              <Logo service={service} />
            </div>
            <div className="col-sm-7">
              <div className="d-flex flex-column h-100">
                <div className="mb-7">
                  <div className="mb-6">
                    <span className="text-secondary fs-7 fw-bold me-2 d-block lh-1 pb-1">CÓDIGO: {service.code}</span>
                    <span className="text-gray-800 text-hover-primary fs-1 fw-bold">
                      <Link className="" to={serviceLink}>
                        {service.name}
                      </Link>
                    </span>
                  </div>
                  <div className="d-flex align-items-start gap-5">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-30px symbol-circle me-2">
                        <i className="bi bi-globe fs-3" />
                      </div>
                      <div className="m-0">
                        <span className="text-muted d-block fs-8">Organización</span>
                        <span className="fw-bold text-gray-800 fs-7">{service.organization.name}</span>
                      </div>
                    </div>
                    {service.enterprise && (
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px symbol-circle me-2">
                          <i className="bi bi-buildings fs-3" />
                        </div>
                        <div className="m-0">
                          <span className="text-muted d-block fs-8">Empresa</span>
                          <span className="fw-bold text-gray-800 fs-7">{service.enterprise.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column border border-1 border-gray-300 p-3 card-rounded">
                  <span className="fw-semibold text-gray-600 fs-7">{service.resume}</span>
                </div>
                <div className="d-flex justify-content-end mt-auto bd-highlight">
                  <Link className="btn btn-sm btn-outline-primary service-btn-show" to={serviceLink}>
                    Ir al servicio
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardService
