import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import PasswordInput from "../../components/PasswordInput"
import { useLogin } from "./hooks/useLogin"

interface LoginInputs {
  email: string
  password: string
}

export const Login = (): React.ReactElement => {
  const { loading, login, hasErrors } = useLogin()
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    setFocus,
  } = useForm<LoginInputs>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const handleLogin = async ({ email, password }: LoginInputs): Promise<void> => {
    await login(email, password)
    reset({ password: "" })
  }

  useEffect(() => {
    document.title = `Mina365 | Bienvenido`
    setFocus("email")
  }, [])

  return (
    <form className="form w-100" onSubmit={handleSubmit(handleLogin)}>
      <div className="mb-10">
        <h2 className="text-center">Ingresa a Mina365</h2>
      </div>

      {Boolean(hasErrors) && (
        <div className="mb-6 alert alert-danger">
          <div className="alert-text font-weight-bold">Email o contraseña incorrectos</div>
        </div>
      )}

      <div className="mb-10">
        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="email">
          Email
        </label>
        <input
          placeholder="ejemplo@mina365.com"
          disabled={loading}
          className={"form-control form-control-lg form-control-solid"}
          type="email"
          autoComplete="off"
          {...register("email", { required: true })}
        />
      </div>

      <div className="mb-3 form_group">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0" htmlFor="contrasena">
              Contraseña
            </label>
          </div>
        </div>
        <PasswordInput
          disabled={loading}
          register={register}
          registerName={"password"}
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          isSolid={true}
          rules={{ required: true }}
          bypassPattern={true}
        />
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-4">
        <div></div>
        <Link to="/auth/forgot_password" className="link-primary fs-7" style={{ marginLeft: "5px" }}>
          ¿Olvidaste tu contraseña?
        </Link>
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={loading || !isValid}>
          {loading ? (
            <div className="spinner-border spinner-border-sm"></div>
          ) : (
            <span className="indicator-label">Ingresar</span>
          )}
        </button>
      </div>
    </form>
  )
}
