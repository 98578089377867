import { Entry } from './activity.types'
import { AutomaticEntry } from './components/entry/AutomaticEntry'
import { ManualEntry } from './components/entry/ManualEntry'
import { useGetListEntries } from './hook/useActivity'
import './activity.css'
import { EntryManagement } from './components/entry/EntryManagement'
import { EntriesLoader } from '../loaders/EntriesLoader'
import EmptyActivity from '../../assets/images/undraw/empty-activity.svg'
import { useMemo } from 'react'
import { useServiceContext } from '../../Services/ServicesShow/hooks/useServiceContext'

type ActivityTimelineProps = {
  resourceId: string
  resourceType: string
  canRegisterEntry: boolean
  canHandleSticky: boolean
  placeholder?: string
  emptyMessage?: string
  registerButtonMessage?: string
}

const ActivityTimeline = ({
  resourceId,
  resourceType,
  canRegisterEntry,
  placeholder,
  emptyMessage,
  canHandleSticky,
  registerButtonMessage
}: ActivityTimelineProps) => {
  const { service } = useServiceContext()

  const { data: entries, isPending: isPendingEntries } = useGetListEntries({
    serviceId: service?.id,
    resourceId,
    resourceType
  })

  const stickyEntries = useMemo(() => {
    return (entries || []).filter((entry: Entry) => entry.sticky)
  }, [entries])

  if (isPendingEntries) {
    return (
      <div className="row">
        <div className="col-12">
          <EntriesLoader />
        </div>
        <div className="col-12">
          <EntriesLoader />
        </div>
      </div>
    )
  }

  const entriesLength = entries.length

  if (entriesLength === 0 && !canRegisterEntry) {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <img src={EmptyActivity} alt="Empty activity" height="100px" className="d-none d-md-block" />
          <div className="d-flex flex-column justify-content-center px-10">
            <span className="fs-4 text-muted text-center py-10 py-md-0">
              {emptyMessage || 'Este recurso no tiene actividad registrada'}
            </span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="row">
      <StickyEntries
        entries={stickyEntries}
        canHandleSticky={canHandleSticky}
        resourceType={resourceType}
        resourceId={resourceId}
      />
      <div className="col-12 px-0">
        {entries.map((entry: Entry, index: number) =>
          entry.action ? (
            <AutomaticEntry
              key={entry.id}
              entry={entry}
              canHaveTimeline={entriesLength - index === 1 && !canRegisterEntry}
            />
          ) : (
            <ManualEntry
              key={entry.id}
              entry={entry}
              resourceType={resourceType}
              resourceId={resourceId}
              canHaveTimeline={entriesLength - index === 1 && !canRegisterEntry}
              canHandleSticky={canHandleSticky}
            />
          )
        )}
      </div>
      {canRegisterEntry && (
        <div className="col-12 px-0">
          <EntryManagement
            resourceId={resourceId}
            resourceType={resourceType}
            placeholder={placeholder}
            registerButtonMessage={registerButtonMessage}
          />
        </div>
      )}
    </div>
  )
}

const StickyEntries = ({
  entries,
  canHandleSticky,
  resourceType,
  resourceId
}: {
  entries: Entry[]
  canHandleSticky: boolean
  resourceType: string
  resourceId: string
}) => {
  if (entries.length === 0) return <></>
  return (
    <div className="card shadow-sm border py-4 mt-0 mb-4 activity__stickyCard">
      {entries.map((entry: Entry) =>
        entry.action ? (
          <AutomaticEntry key={entry.id} entry={entry} canHaveTimeline={true} />
        ) : (
          <ManualEntry
            key={entry.id}
            entry={entry}
            canHaveTimeline={true}
            canHandleSticky={canHandleSticky}
            resourceType={resourceType}
            resourceId={resourceId}
          />
        )
      )}
    </div>
  )
}

export default ActivityTimeline
