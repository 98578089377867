import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams, Navigate } from "react-router-dom"
import PasswordInput from "../../components/PasswordInput"
import { useAcceptInvitation } from "./hooks/useAcceptInvitation"
import { InvalidToken } from "./InvalidToken"

interface AcceptInputs {
  name: string
  password: string
}

interface InvitationFormProps {
  token: string
}

export const AcceptForm = ({ token }: InvitationFormProps): React.ReactElement => {
  const { loading, acceptInvitation, hasErrors } = useAcceptInvitation()

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setFocus,
  } = useForm<AcceptInputs>({
    mode: "onChange",
    defaultValues: {
      name: "",
      password: "",
    },
  })

  const handleAccept = ({ name, password }: AcceptInputs): void => {
    void acceptInvitation(token, name, password)
  }

  useEffect(() => {
    document.title = `Mina365 | Finaliza tu registro`
    setFocus("name")
  }, [])

  return (
    <form className="form w-100" onSubmit={handleSubmit(handleAccept)}>
      <div className="mb-5 text-center">
        <h2 className="mb-3">Finaliza tu registro en Mina365</h2>
        <div className="text-gray-700 fs-6">Completa tus datos</div>
      </div>

      {hasErrors && (
        <div className="mb-5 alert alert-danger">
          <div className="alert-text font-weight-bold">Nueva contraseña incorrecta, debe tener 6 caracteres.</div>
        </div>
      )}

      <div className="mb-5">
        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="name">
          Nombre completo
        </label>
        <input
          id="name"
          disabled={loading}
          placeholder=""
          type="text"
          autoComplete="off"
          className={"form-control form-control-lg form-control-solid"}
          {...register("name", { required: true })}
        />
      </div>

      <div className="mb-8 form_group">
        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="new">
          Nueva contraseña
        </label>
        <PasswordInput
          disabled={loading}
          register={register}
          registerName={"password"}
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          isSolid={true}
          rules={{ required: true, minLength: 8 }}
          showPasswordStrong={true}
        />
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={loading || !isValid}>
          {loading ? (
            <div className="spinner-border spinner-border-sm"></div>
          ) : (
            <span className="indicator-label">Completar registro</span>
          )}
        </button>
      </div>
    </form>
  )
}

export const AcceptInvitation = (): React.ReactElement => {
  const [searchParams] = useSearchParams()
  const [token] = useState(searchParams.get("invitation_token"))
  const { checkInvitationToken, hasErrors } = useAcceptInvitation()

  useEffect(() => {
    if (token != null) {
      void checkInvitationToken(token)
    }
  }, [])

  return (
    <>{token == null ? <Navigate to="/auth" /> : <>{hasErrors ? <InvalidToken /> : <AcceptForm token={token} />}</>}</>
  )
}
