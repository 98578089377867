import { createContext, useState } from 'react'
import {
  ReservationContextProviderProps,
  ReservationPropsTableContextType,
  ReservationPropsTableType,
} from '../../reservations.types'

export const TablePropsContext = createContext<ReservationPropsTableContextType>({
  reservationPropsTable: null,
})

export const ReservationTablePropsContextProvider = ({
  children,
}: ReservationContextProviderProps) => {
  const [reservationPropsTable, setReservationPropsTable] =
    useState<ReservationPropsTableType | null>(null)

  return (
    <TablePropsContext.Provider value={{ reservationPropsTable, setReservationPropsTable }}>
      {children}
    </TablePropsContext.Provider>
  )
}
