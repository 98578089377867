import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { ServiceBD } from '../services.types'

const Logo = ({ service }: { service: ServiceBD }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  useEffect(() => {
    async function fetchImage() {
      if (service.show_enterprise_logo && service.enterprise?.logo) {
        setImageSrc(service.enterprise.logo.print || service.enterprise.logo.default)
      } else {
        const image = await import(`../../assets/images/services-illustrations/${service.illustration}.svg`)
        setImageSrc(image.default)
      }
    }

    fetchImage()
  }, [service])

  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }

  return (
    <div className="image-container">
      {!isImageLoaded && (
        <ContentLoader
          speed={2}
          width={150}
          height={150}
          viewBox="0 0 150 150"
          backgroundColor="#f3f3f3"
          foregroundColor="#f3f3f3"
        >
          <rect x="0" y="0" rx="5" ry="5" width="150" height="150" />
        </ContentLoader>
      )}
      <img
        alt={service.name}
        className={`image-element ${isImageLoaded ? 'loaded' : 'loading'}`}
        src={imageSrc || ''}
        onLoad={handleImageLoad}
        style={{ display: isImageLoaded ? 'block' : 'none' }}
      />
    </div>
  )
}

export default Logo
