import { useContext } from 'react'
import { ServiceContext } from '../contexts/ServiceContext'
import { ServiceContextType } from '../../services.types'

export const useServiceContext = (): ServiceContextType => {
  const context = useContext(ServiceContext)

  if (!context) {
    throw Error('useServiceContext must be inside an useServiceContextProvider')
  }

  return context
}
