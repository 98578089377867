import { Link } from 'react-router-dom'

export const InvalidToken = (): React.ReactElement => {
  return (
    <div className="w-100">
      <div className="text-center mb-10">
        <h1 className="text-gray-900 mb-3">Este link ha expirado</h1>
        <div className="text-gray-700 fw-bold fs-6">
          Este link tiene un tiempo de expiración por razones de seguridad, por favor solicita otro
          link.
        </div>
      </div>
      <div className="text-center">
        <Link to="/auth/forgot_password" className="btn btn-lg btn-primary w-100 mb-5">
          <span className="indicator-label">¿Olvidaste tu contraseña?</span>
        </Link>
      </div>
    </div>
  )
}
