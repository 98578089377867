import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosPrivate } from '../../../setup/axios'
import { useEffect, useState } from 'react'
import { useUnitsOfMeasureContext } from './useUnitsOfMeasureContext'

const fetchUnitOfMeasures = async (id: string | undefined) => {
  const response = await axiosPrivate.get(`/services/${id}/units_of_measure`)
  return response.data.units_of_measure
}

export const useUnitsOfMeasureInit = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const { setUnitsOfMeasure } = useUnitsOfMeasureContext()

  const { data: unitsOfMeasure } = useQuery({
    queryKey: ['service', id, 'unitsOfMeasure'],
    queryFn: () => fetchUnitOfMeasures(id)
  })

  useEffect(() => {
    if (unitsOfMeasure && setUnitsOfMeasure) {
      setUnitsOfMeasure(unitsOfMeasure)
      setIsLoading(false)
    }
  }, [unitsOfMeasure])

  return { isLoading }
}
