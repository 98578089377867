import { useState } from "react"
import axios, { axiosPrivate } from "../../../setup/axios"

import { useAuthContext } from "../../../setup/hooks/useAuthContext"
import { ActionEnum } from "../../../setup/auth.types"

type loginType = (email: string, password: string) => Promise<void>

export const useLogin = (): {
  loading: boolean
  login: loginType
  hasErrors: boolean
} => {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const { dispatch } = useAuthContext()

  const login = async (email: string, password: string): Promise<void> => {
    setLoading(true)
    setHasErrors(false)

    try {
      const response = await axios.post("/oauth/token", {
        grant_type: "password",
        email,
        password,
        client_id: import.meta.env.VITE_REACT_APP_CLIENT_ID,
      })

      axiosPrivate.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`

      // On dispatch we set the token in the local storage

      if (dispatch != null) {
        dispatch({
          type: ActionEnum.Login,
          payload: response.data.access_token,
        })
      }
      setLoading(false)
    } catch (error) {
      // We should differentiate between different errors.
      // We should throw an error in case of bad client credentials
      setLoading(false)
      setHasErrors(true)
    }
  }

  return { loading, login, hasErrors }
}
