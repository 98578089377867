import React, { LegacyRef } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import './header.css'
import { useLogout } from '../../../features/authentication/hooks/useLogout'
import { useAccountContext } from '../../../setup/hooks/useAccountContext'
import { useNavigate } from 'react-router-dom'
import UserAvatar from '../../../components/users/UserAvatar'

interface CustomToggleProps {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProps, ref: LegacyRef<HTMLDivElement> | undefined) => (
    <div
      className="cursor-pointer symbol symbol-30px symbol-md-40px"
      ref={ref}
      onClick={(e) => {
        onClick(e)
      }}
    >
      {children}
    </div>
  )
)

interface CustomMenuProps {
  children?: React.ReactNode
  style?: React.CSSProperties
  className: string
  'aria-labelledby': string
}

const CustomMenu = React.forwardRef(
  ({ children, style, className }: CustomMenuProps, ref: LegacyRef<HTMLDivElement> | undefined) => {
    return (
      <div
        ref={ref}
        style={{ ...style }}
        className={
          className +
          ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px'
        }
      >
        {children}
      </div>
    )
  }
)

export const HeaderUserMenu = (): React.ReactElement => {
  const { logout } = useLogout()
  const { account } = useAccountContext()
  const navigate = useNavigate()
  const appUrl = import.meta.env.VITE_REACT_APP_URL
  const markeplaceUrl = appUrl + '/mercado-minero'

  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3">
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="header-user-menu">
          <UserAvatar user={account} size={'40'} round={'20px'} />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="d-flex flex-column">
                <div className="fw-bold d-flex align-items-center fs-5">
                  {account?.name}
                  <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span>
                </div>
                <span className="fw-semibold text-muted fs-7">{account?.email}</span>
              </div>
            </div>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-5">
            <Dropdown.Item eventKey={1} onClick={() => navigate('/profile')} className="menu-link px-5">
              Mi Cuenta
            </Dropdown.Item>

            <Dropdown.Item eventKey={2} href={markeplaceUrl} className="menu-link px-5">
              Ir al Mercado Minero
            </Dropdown.Item>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-5">
            <span className="menu-link px-5" onClick={async () => await logout()}>
              Cerrar sesión
              <i className="bi bi-arrow-bar-right mx-2"></i>
            </span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
