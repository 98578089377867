import moment from 'moment'
import { Entry } from '../../../activity.types'
import { AttachmentManagement } from '../../attachment/AttachmentManagement'

export const RequirementsAcceptanceFiles = ({
  entry,
  canHaveTimeline,
}: {
  entry: Entry
  canHaveTimeline: boolean
}) => {
  return (
    <div className="position-relative activity__timelineItem ">
      <div
        className="activity__timelineLine w-40px"
        style={{ borderLeftColor: !canHaveTimeline ? '#ccc' : 'transparent' }}
      ></div>
      <div className="me-2 d-flex align-items-center justify-content-center rounded-circle w-30px h-30px min-w-30px bg-success">
        <i className="fs-3 bi text-white activity__icon bi-files"></i>
      </div>
      <div className="activity-content pb-2">
        <div className="activity__header">
          <div className="activity__span fw-bold me-2">Documentos del requerimiento</div>
          <div className="activity__date text-secondary fs-7">
            {moment(entry.created_at).format('DD MMM h:mm A')}
          </div>
        </div>
        <div className="activity__body mt-2 pb-5 border-bottom border-1">
          <EntryAttachment entry={entry} />
        </div>
      </div>
    </div>
  )
}

const EntryAttachment = ({ entry }: { entry: Entry }) => {
  if (entry.processing)
    return (
      <div className="d-flex flex-column align-items-center justify-content-center w-100 text-success">
        Se están procesando los documentos
        <div className="requirement_atacchmentLoader my-2"></div>
      </div>
    )
  return (
    <>
      {entry.attachments.length !== 0 && <AttachmentManagement attachments={entry.attachments} />}
    </>
  )
}
