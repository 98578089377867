import { createContext, useState } from 'react'
import { LastReservation, LastReservationContextType } from '../../reservations.types'

export const LastReservationContext = createContext<LastReservationContextType>({
  lastReservation: null,
  loading: true,
})

export const LastReservationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [lastReservation, setLastReservation] = useState<LastReservation | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <LastReservationContext.Provider
      value={{ lastReservation, setLastReservation, loading, setLoading }}
    >
      {children}
    </LastReservationContext.Provider>
  )
}
