import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams, Navigate } from "react-router-dom"
import PasswordInput from "../../components/PasswordInput"
import { useResetPassword } from "./hooks/useResetPassword"
import { InvalidToken } from "./InvalidToken"

interface ResetInputs {
  password: string
}

interface ResetFormProps {
  token: string
}

export const ResetForm = ({ token }: ResetFormProps): React.ReactElement => {
  const { loading, resetPassword, hasErrors } = useResetPassword()

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ResetInputs>({
    mode: "onChange",
    defaultValues: {
      password: "",
    },
  })

  const handleReset = ({ password }: ResetInputs): void => {
    void resetPassword(token, password)
  }

  return (
    <form className="form w-100" onSubmit={handleSubmit(handleReset)}>
      <div className="text-center mb-10">
        <h2 className="mb-3">Ingresa nueva contraseña</h2>
        <div className="text-gray-700 fs-6">Ingresa una nueva contraseña con mínimo 6 caracteres</div>
      </div>

      {hasErrors && (
        <div className="mb-6 alert alert-danger">
          <div className="alert-text font-weight-bold">Nueva contraseña incorrecta, debe tener 6 caracteres</div>
        </div>
      )}

      <div className="mb-10 form_group">
        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="email">
          Nueva contraseña
        </label>
        <PasswordInput
          disabled={loading}
          register={register}
          registerName={"password"}
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          isSolid={true}
          rules={{ required: true, minLength: 8 }}
          showPasswordStrong={true}
        />
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={loading || !isValid}>
          {loading ? (
            <div className="spinner-border spinner-border-sm"></div>
          ) : (
            <span className="indicator-label">Cambiar contraseña</span>
          )}
        </button>
      </div>
    </form>
  )
}

export const ResetPassword = (): React.ReactElement => {
  const [searchParams] = useSearchParams()
  const [token] = useState(searchParams.get("reset_token"))
  const { checkResetToken, hasErrors } = useResetPassword()

  useEffect(() => {
    if (token != null) {
      void checkResetToken(token)
    }
  }, [])

  return (
    <>{token == null ? <Navigate to="/auth" /> : <>{hasErrors ? <InvalidToken /> : <ResetForm token={token} />}</>}</>
  )
}
